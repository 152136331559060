@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  .recent-activity {
    @include general.reset-list();
    margin-top: variables.$spacing-md;

    li {
      display: flex;
      margin-bottom: variables.$spacing-sm;
      padding-left: variables.$spacing-xl;
      background-repeat: no-repeat;
      background-position: 0 5px;

      &.invoice {
        background-image: url('../../../../../../theme/icons/activity-invoice.svg');
      }
      &.receipt {
        background-image: url('../../../../../../theme/icons/activity-receipt.svg');
      }
      &.payment {
        background-image: url('../../../../../../theme/icons/activity-payment.svg');
      }
      &.payment-reversed {
        background-image: url('../../../../../../theme/icons/activity-payment-reversed.svg');
      }
      &.credit-note {
        background-image: url('../../../../../../theme/icons/activity-credit-note.svg');
      }

      .description {
        display: flex;
        flex-direction: column;
        max-width: 79%;
      }
      .amount {
        margin-left: auto;
      }
    }
  }
}
