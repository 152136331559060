@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  min-height: 400px;

  [class*='FundDistribution_edit__'] {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 0 variables.$spacing-md;
  border-bottom: 1px solid colors.$color-carbon-accent;
  justify-content: space-between;

  .close {
    @include button.button-reset;
    background: url('../../../../theme/icons/arrow-left.svg') no-repeat left center;
    padding: 27px variables.$spacing-md;
    outline: none;
  }

  .menu {
    padding-left: 10px;
    margin-left: 20px;
  }
}

.due-date {
  margin-left: auto;
  width: 40%;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: variables.$spacing-lg;
  padding-bottom: variables.$spacing-lg;
}

.body {
  padding: variables.$spacing-lg;
}
