@use 'src/theme/styles/variables';

.root {
  position: relative;
  padding: variables.$spacing-lg;

  label {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: variables.$font-weight-medium;
    margin: 0;
    padding: 10px 0 33px 0;
  }
}
