@use '../variables';

@mixin mq($media) {
  @if $media == handhelds {
    @media only screen and (max-width: variables.$break-medium) {
      @content;
    }
  } @else if $media == medium-screens-up {
    @media only screen and (min-width: variables.$break-medium + 1) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: variables.$break-medium + 1) and (max-width: variables.$break-large - 1) {
      @content;
    }
  } @else if $media == large-screens {
    @media only screen and (min-width: variables.$break-large) and (max-width: variables.$break-wide - 1) {
      @content;
    }
  } @else if $media == large-screens-up {
    @media only screen and (min-width: variables.$break-large) {
      @content;
    }
  } @else if $media == larger-screens {
    @media only screen and (min-width: variables.$break-larger) and (max-width: variables.$break-wide - 1) {
      @content;
    }
  } @else if $media == larger-screens-up {
    @media only screen and (min-width: variables.$break-larger) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: variables.$break-wide) {
      @content;
    }
  }
}
