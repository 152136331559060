@use 'src/theme/styles/variables';

.root {
  ul {
    padding: 0;
    list-style: none;
  }
}

.header {
  > div {
    margin-bottom: variables.$spacing-sm;
  }
}
