@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  text-align: center;
}

.title {
  margin-top: 0;
  font-weight: variables.$font-weight-regular;
}

.instruction {
  color: colors.$color-white-60;
  font-weight: variables.$font-weight-thin;

  a {
    color: colors.$color-link;
  }
}

.timer {
  display: inline-block;
  padding: variables.$spacing-sm variables.$spacing-xxl;
  border: 1px solid colors.$color-white-50;
  border-radius: variables.$radius-sm;
  text-align: center;
  margin: variables.$spacing-sm 0 variables.$spacing-xl;
}

.pin {
  display: flex;

  input {
    width: 40px;
    height: 56px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid transparent;
    border-radius: variables.$radius-sm;
    margin-right: variables.$spacing-sm;
    color: colors.$color-white;
    font-size: variables.$font-size-a2;
    text-align: center;

    &[disabled] {
      cursor: not-allowed;
      background-color: rgba(255, 255, 255, 0.05);
    }

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      border-color: #006eff;
      outline: none;
    }
  }
}
