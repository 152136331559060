@mixin ir() {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

@mixin reset-list() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin fill-absolute() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin hide-scrollbar() {
  // FireFox
  scrollbar-width: none;
  // IE10
  -ms-overflow-style: -ms-autohiding-scrollbar;
  // webkit
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin styled-scrollbar() {
  overflow: hidden !important;
  transition: 0.2s;
  scrollbar-gutter: stable both-edges;

  &:hover {
    overflow: auto !important;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.64);
  }
}
