@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/mixins/media-query';

$image-width: 96px;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: variables.$z-index-4;
  padding: variables.$spacing-sm 0 120px variables.$spacing-sm;

  button {
    @include button.button-reset;
    outline: none;
  }

  @include media-query.mq(medium-screens-up) {
    padding-bottom: 0;
  }
}

.logo {
  display: flex;
  padding: 64px 0;
  justify-content: center;

  img {
    width: $image-width;
    height: $image-width;
    border: 2px solid colors.$color-carbon-tint;
    border-radius: variables.$radius-sm;
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.03);
  }
}
