@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/functions';

.filters {
  @include general.reset-list();

  .filter-item {
    float: left;

    button {
      @include button.button-reset;
      display: flex;
      align-items: center;
      outline: none;
      padding: variables.$spacing-lg variables.$spacing-md;
      margin: 0 variables.$spacing-sm;
      font-size: functions.convert-to-rem(16);
      color: colors.$color-grey-primary;
      border-bottom: 4px solid transparent;

      span:first-child {
        margin-right: variables.$spacing-sm;
      }

      &:hover,
      &:focus {
        color: colors.$color-black;
      }
    }

    &:first-child button {
      margin-left: 0;
    }

    &.active {
      button {
        color: colors.$color-black;
        border-bottom-color: colors.$color-black-secondary;
      }
    }
  }
}
