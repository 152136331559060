@use '../variables';

@mixin fontBigBoy() {
  font-size: variables.$font-size-a1;
  font-weight: variables.$font-weight-medium;
}

@mixin fontHeadline() {
  font-size: variables.$font-size-a2;
  font-weight: variables.$font-weight-medium;
}

@mixin fontTitle() {
  font-size: variables.$font-size-a3;
  font-weight: variables.$font-weight-medium;
}

@mixin fontMainline() {
  font-size: variables.$font-size-body-1;
  font-weight: variables.$font-weight-medium;
}

@mixin fontSubheader() {
  font-size: variables.$font-size-body-1;
  font-weight: variables.$font-weight-regular;
}

@mixin fontMenu() {
  font-size: variables.$font-size-body-2;
  font-weight: variables.$font-weight-medium;
}
