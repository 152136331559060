@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  .error {
    color: colors.$color-error;
  }

  .form {
    display: flex;
    width: 100%;
    padding: variables.$spacing-md 0;
  }

  .input {
    flex: 1;
  }

  button:first-child {
    padding-left: 0;
  }
}
