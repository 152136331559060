@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  padding: variables.$spacing-md;
  background-color: colors.$color-black-primary;
  color: colors.$color-white-primary;
  border-radius: variables.$radius-lg;
  border-left: 3px solid;
  position: relative;

  &.success {
    border-color: colors.$color-success;
  }

  &.info {
    border-color: colors.$color-info;
  }

  &.warning {
    border-color: colors.$color-warning;
  }

  &.error {
    border-color: colors.$color-error;
  }
}

.closeButton {
  position: absolute;
  top: variables.$spacing-sm;
  right: variables.$spacing-sm;
  opacity: 0.8;
  @include button.button-reset;
  outline: none;
  transition: opacity variables.$transition-duration variables.$transition-timing-function;

  > svg {
    filter: brightness(100);
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.heading {
  font-size: variables.$font-size-body-1;
}
