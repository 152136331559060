@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  display: inline-block;
  background-color: colors.$color-black;
  border-radius: 0 12px 12px 12px;
  opacity: 0.8;
  color: colors.$color-white;
  padding: 9px 15px;
  font-size: 24px;
}
