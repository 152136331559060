@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/animations';

$circle-size: 40px;

// Unfortunatly we need a wrapper element containing the fixed width for centering the
// button within the animtion (you could also apply the width as an inline style).
.root {
  display: flex;
  height: $circle-size;
}

.button {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: variables.$spacing-xl;
  padding-right: variables.$spacing-xl;
  border: 0;

  cursor: pointer;

  transition: all 0.33s ease-in-out;

  > .text {
    transition: all 2.5s ease;
    opacity: 1;
    width: auto;
  }

  // Styles for all states
  &--loading,
  &--success,
  &--error {
    // Morph button to circle (width equals height)
    min-width: $circle-size;
    width: $circle-size;
    border-radius: 24px;
    padding-left: 0;
    padding-right: 0;

    // Prevent any further clicks triggering events during animation
    pointer-events: none;
    cursor: default;

    // Hide text
    > .text {
      transition: all 0.1s ease;
      opacity: 0;
      width: 0;
    }
  }

  // State "loading"
  // Show loading indicator
  &--loading:before {
    content: '';

    top: 50%;
    left: 50%;

    // Can't use percentage here as we already show this icon during morph animation
    height: #{$circle-size * 0.7};
    width: #{$circle-size * 0.7};

    background: url('../../../../theme/icons-v2/Controls/Loader.svg') center center;
    filter: brightness(10);

    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    animation: linear spin 1000ms infinite, loading-btn--fade-in 0.6s ease;
  }

  // Success state - show check icon
  &--success {
    // Different background color (also on hover)
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: colors.$color-success;
    }

    // Use "after" pseudo to trigger new fade in animation, as "before" is already used on "--loading"
    &:after {
      content: '';

      top: 50%;
      left: 50%;
      height: #{$circle-size * 0.7};
      width: #{$circle-size * 0.7};

      background: url('../../../../theme/icons-v2/Controls/Check.svg') center center;
      filter: brightness(100);

      animation: loading-btn--fade-in 0.6s ease;
    }
  }

  // Fail state - show cross icon
  &--error {
    // Different background color (also on hover)
    &,
    &:hover,
    &:focus {
      background: colors.$color-error;
    }

    // Use "after" pseudo to trigger new fade in animation, as "before" is already used on "--loading"
    &:after {
      content: '';

      top: 50%;
      left: 50%;

      height: #{$circle-size * 0.7};
      width: #{$circle-size * 0.7};

      background: url('../../../../theme/icons-v2/Controls/Close.svg') center center;
      filter: brightness(100);

      animation: loading-btn--fade-in 0.6s ease;
    }
  }
}

@keyframes loading-btn--fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
