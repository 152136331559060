@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/typography';

.root {
  position: relative;
  border-radius: variables.$radius-md;
  padding: variables.$spacing-md;
  border-width: 2px;
  border-style: solid;
  color: colors.$color-black-primary;

  header {
    padding-bottom: 64px;
    span:last-of-type {
      @include typography.interDisambiguation();
    }
  }
}

.status-good {
  background: colors.$color-dark-blue-tint;
  border-color: colors.$color-dark-blue-secondary;
}

.account-meta {
  display: flex;
  justify-content: space-between;
  min-height: 24px;
  span {
    border-radius: 90px;
    padding: 6px variables.$spacing-sm;
    background: colors.$color-carbon-primary;
    color: colors.$color-white;
    text-transform: uppercase;
    font-size: variables.$font-size-fine;
    letter-spacing: 0.4px;
  }

  .account-actions {
    z-index: variables.$z-index-4;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    margin: 12px -#{variables.$spacing-md} 12px;
    height: 3px;
    background-color: colors.$color-carbon-tint;
    border: none;
  }
}

.address {
  padding: variables.$spacing-sm;
  padding-top: variables.$spacing-md;

  small {
    color: colors.$color-grey-primary;
  }
}
