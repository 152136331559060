@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.apply-segment,
.delete-segment,
.edit-segment {
  @include button.button-reset;
  position: relative;
  outline: none;
  width: 32px;
  height: 32px;
  transition: all variables.$transition-duration variables.$transition-timing-function;
  opacity: 0.8;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.delete-segment {
  background: url('../../../../theme/icons-v2/Controls/Delete.svg') no-repeat;
}

.edit-segment {
  background: url('../../../../theme/icons-v2/Controls/Edit.svg') no-repeat;
}

.no-segments-button {
  @include button.button-reset;
  color: colors.$color-link;

  &:hover,
  &:focus {
    color: colors.$color-link-hover;
  }
}
