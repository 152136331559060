@use 'src/theme/styles/variables';

.root {
  position: relative;
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}
