@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  ul {
    @include general.reset-list();
    li {
      display: flex;
      margin-bottom: variables.$spacing-sm;

      span:last-child {
        margin-left: auto;
      }
    }
  }
}
