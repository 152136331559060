@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  overflow-x: hidden;
  width: 100vw;
  @include general.styled-scrollbar;

  @media #{variables.$xxxl} {
    margin-right: 375px;
  }

  > header {
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: variables.$spacing-xxxl variables.$spacing-lg variables.$spacing-md;
    color: colors.$color-text;
    border-bottom: 1px solid colors.$color-carbon-tint;

    h1 {
      font-size: variables.$font-size-a1;
      font-weight: variables.$font-weight-bold;
      margin: 0;
      padding: variables.$spacing-xxxl 0 variables.$spacing-sm;
    }

    h2 {
      font-size: variables.$font-size-a2;
      font-weight: variables.$font-weight-medium;
      margin: 0;
      padding: 0 0 variables.$spacing-lg;
    }
  }

  .menu-button {
    padding-right: 0;
  }

  .body {
    > section {
      padding: variables.$spacing-lg;

      h2,
      h3 {
        color: colors.$color-text;
      }

      &:nth-child(2) {
        border-bottom: 1px solid colors.$color-carbon-tint;
      }

      &:last-child {
        padding-bottom: 90px;
      }
    }
  }

  @media #{variables.$desktop} {
    .body {
      > section {
        h3 {
          font-size: variables.$font-size-a3;
        }

        // Temp styling, there's probably a better way to align the legend with heading
        &.chart-section {
          position: relative;
          display: flex;

          h3 {
            position: absolute;
          }

          > div {
            margin-top: variables.$spacing-lg;
            width: 50%;
          }
        }
      }
    }
  }
}
