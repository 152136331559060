@use 'src/theme/styles/variables';

.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 120px;
}

.button {
  cursor: pointer;
}

.action {
  position: relative;
  cursor: pointer;
  border: 0px;
  margin-top: 10px;
  outline: none;
  width: 32px;
  height: 32px;
  transition: all variables.$transition-duration variables.$transition-timing-function;
}

.send-invite-send {
  background: url('../../../../theme/icons-v2/Controls/Send.svg') no-repeat;

  &:hover,
  &:focus {
    background-image: url('../../../../theme/icons-v2/Controls/Send-fill.svg');
  }
}

.send-invite-revoke {
  background: url('../../../../theme/icons-v2/Controls/Denied.svg') no-repeat;

  &:hover,
  &:focus {
    background-image: url('../../../../theme/icons-v2/Controls/Denied-fill.svg');
  }
}
