@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.button-wrapper {
  display: flex;
  align-items: center;
}

.state-text {
  text-transform: uppercase;
  font-weight: variables.$font-weight-bold;
  margin-left: variables.$spacing-md;

  // Styles for all states
  &--loading {
    color: colors.$color-dark-blue-secondary;
  }
  &--success {
    color: colors.$color-green-secondary;
  }
  &--error {
    color: colors.$color-deep-red-secondary;
  }
}
