@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  margin-top: variables.$spacing-xxl;

  > h2,
  > p {
    margin: 0 variables.$spacing-md variables.$spacing-lg;
  }

  h2 {
    margin-bottom: variables.$spacing-sm;
  }
}

.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: custom-counter;

  li {
    position: relative;
    background: colors.$color-white-primary;
    padding: 0;
    border-radius: variables.$radius-md;
    margin-bottom: variables.$spacing-sm;
    counter-increment: custom-counter;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:hover {
      background-color: colors.$color-white;
      box-shadow: -2px 2px 2px colors.$color-grey-accent;

      &:before {
        background: colors.$color-system-primary;
      }
    }

    &:before {
      content: counter(custom-counter);
      color: colors.$color-white-primary;
      background: colors.$color-carbon-secondary;
      position: absolute;
      --size: 32px;
      left: calc(-1 * var(--size) - 10px);
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      top: 12px;
      left: variables.$spacing-md;
      border-radius: 50%;
      text-align: center;
      transition: background variables.$transition-duration variables.$transition-timing-function;
    }

    &.complete {
      &:before {
        background: colors.$color-system-primary;
      }
      a {
        &:after {
          content: '';
          display: block;
          width: 32px;
          height: 32px;
          position: absolute;
          top: 11px;
          right: 8px;
          background: url('../../../../theme/icons/validated.svg') no-repeat;
        }
      }
    }

    button {
      @include button.button-reset;
      outline: none;
      display: block;
      color: inherit;
      text-decoration: none;
      padding: variables.$spacing-md;
      padding-left: 60px;
      transition: all variables.$transition-duration variables.$transition-timing-function;
      width: 100%;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }

      &:hover,
      &:focus {
        transform: translateX(2px);
      }
    }

    h3 {
      margin: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}
