@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  position: relative;
  width: 100%;

  .list {
    > div {
      display: flex;
      align-items: flex-start;
      position: absolute;
      width: 100%;
      overflow: visible;
      pointer-events: auto;
      transform-origin: 50% 50% 0px;
      background: colors.$color-white;

      .controls {
        display: flex;
        flex-direction: column;
        margin-right: variables.$spacing-md;

        button {
          opacity: 0.5;
          transition: all variables.$transition-duration variables.$transition-timing-function;
        }
      }

      .drag-handle,
      .remove {
        outline: none;

        &:hover,
        &:active {
          opacity: 1;
          transform: scale(1.3);
        }
      }

      .drag-handle {
        @include button.button-reset;
        width: 14px;
        height: 24px;
        cursor: grab;
        background: url('../../../../theme/icons/drag-handle.svg') no-repeat center;
        background-size: contain;
        outline: none;
        padding-left: 0;
      }

      &.is-dragging .drag-handle {
        cursor: grabbing;
      }

      .remove {
        @include button.button-reset;
        background: url('../../../../theme/icons-v2/Controls/Delete.svg') no-repeat center;
        background-size: cover;
        padding: 10px;
        padding-left: 0;
        width: 14px;
        height: 28px;

        &--disabled {
          cursor: not-allowed;
          opacity: 0.2 !important;

          &:hover,
          &:active {
            // opacity: 0.2;
            transform: scale(1);
          }
        }
      }
    }
  }

  .add-item-wrapper {
    margin-top: 15px;
  }
}

.add-item {
  display: flex;
  flex-direction: row;
  padding: variables.$spacing-sm variables.$spacing-md;
  margin: variables.$spacing-lg 0;

  svg {
    margin-left: variables.$spacing-sm;
  }
}
