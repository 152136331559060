@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/media-query';

$border-color: colors.$color-carbon-tint;

.root {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $border-color;

  > div {
    width: 100%;
    border-color: $border-color;
    border-style: solid;
    border-width: 0;
    &:not(:last-child) {
      border-right-width: 1px;
      border-bottom-width: 1px;
    }

    > h3 {
      padding: variables.$spacing-sm variables.$spacing-md;
      margin: 0;
      border-bottom: 1px solid $border-color;
    }

    > div {
      border-bottom: 1px solid $border-color;

      &:last-child {
        border: none;
      }
    }
  }

  @media #{variables.$md} {
    > div {
      width: 50%;
    }
  }

  @include media-query.mq(large-screens-up) {
    > div {
      width: 25%;
    }
  }
}
