@use 'src/theme/styles/colors';

.root {
  ul[class*='InfoList_root'] {
    li {
      margin-bottom: 0;
      span {
        padding: 0;
      }
      span:first-child {
        min-width: 120px;
      }
    }
  }
}

.info {
  li {
    span {
      color: white;

      &:first-child {
        font-weight: bold;
      }
    }
  }
}

a {
  color: colors.$color-link;
}

.sub-header {
  span {
    font-weight: bold;
    text-decoration: underline;
  }
}
