@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  transition: all variables.$transition-duration variables.$transition-timing-function;
  overflow: hidden;
}

.wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 0 variables.$spacing-lg;
  border-bottom: 1px solid colors.$color-carbon-tint;
}

.container {
  display: flex;
  flex: 1;
  align-items: center;

  // .dropdown-filters {
  //   flex: 1;
  // }

  /**
   * Was using https://www.npmjs.com/package/css-element-queries but it's buggy for some reason.
   */

  // &[min-width~='600px'] .dropdown-filters {
  //   display: none;
  // }

  // &[min-width~='600px'] .expanded-filters {
  //   display: block;
  // }
}
