@use 'src/theme/styles/variables';
@use 'src/theme/styles/colors';

.root {
  margin-top: 20px;

  [class^='Table_tbody'] {
    > div > div {
      overflow: visible !important;
      &:hover,
      &:focus {
        overflow: visible !important;
      }
    }
  }

  .applications-details {
    display: flex;
    flex-direction: column;
  }

  .application-fee {
    [class^='Button_root'] {
      border: none;
      padding: 16px 20px;
    }
  }

  .recon-status {
    margin-right: 0.5rem;

    span {
      color: #fff;
      opacity: 0.64;
    }

    .status-nofeeset,
    .status-draftinvoice,
    .status-pending {
      background-color: colors.$color-carbon-secondary;
    }

    .status-creditnote {
      background-color: colors.$color-black-primary;
    }

    .status-refunded,
    .status-invoicesent {
      background-color: colors.$color-orange;
    }

    .status-partiallypaid {
      background-color: colors.$color-yellow;
    }

    .status-paid {
      background-color: colors.$color-green;
    }
  }

  .subtext {
    font-size: variables.$font-size-caption;
  }

  .no-content {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }

  .application-status-pending,
  .application-status-accepted,
  .application-status-rejected {
    text-align: left;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }

  .application-status-pending {
    background-image: url('../../../../theme/icons-v2/User/Contact-fill.svg');
  }

  .application-status-accepted {
    background-image: url('../../../../theme/icons-v2/User/Contact-verify-fill-success.svg');
  }

  .application-status-rejected {
    background-image: url('../../../../theme/icons-v2/User/Contact-delete-fill-error.svg');
  }

  .edit {
    text-align: left;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-image: url('../../../../theme/icons-v2/Controls/Edit.svg');
  }
}
