@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  width: 100%;

  a {
    color: colors.$color-link;

    &:hover,
    &:focus {
      color: colors.$color-link-hover;
    }
  }
}

.understand {
  margin-top: variables.$spacing-lg;
}

.footer {
  margin-top: variables.$spacing-xxl;

  button {
    margin-right: variables.$spacing-md;
  }
}
