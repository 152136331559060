@use 'variables';
@use 'colors';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: colors.$color-text !important;
  -webkit-box-shadow: 0 0 0 30px colors.$color-white inset;
  border-color: colors.$color-black-20;
  transition: all variables.$transition-duration variables.$transition-timing-function;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;

  &[disabled] {
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  }
}
