@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

// Input
$input-font-size: variables.$text-field-font-size;
$input-border-color: colors.$color-grey-secondary;
$input-background-color: colors.$color-white;
$input-border-radius: variables.$radius-sm;
$input-placeholder-color: colors.$color-carbon-secondary;
$input-height: 48px;

// Disabled
$input-disabled-background-color: rgba(27, 32, 33, 0.04);
$input-disabled-placeholder-color: colors.$color-black-30;

// Focused
$input-focus-border-color: colors.$color-dark-blue;
$label-font-size: variables.$font-size-caption;

// Label
$label-color: colors.$color-black-secondary;
$input-focused-label-color: colors.$color-black;

@mixin text-input-base() {
  label {
    display: block;
    font-size: $label-font-size;
    color: $label-color;
    margin: 4px 0;
    cursor: pointer;
    transition: all variables.$transition-duration variables.$transition-timing-function;
  }

  input,
  textarea {
    width: 100%;
    background: $input-background-color;
    border: 2px solid $input-border-color;
    border-radius: $input-border-radius;
    font-size: $input-font-size;
    padding: 0 variables.$spacing-md;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:focus {
      outline: none;
      border-color: $input-focus-border-color;
    }

    &::placeholder {
      color: $input-placeholder-color;
      font-size: variables.$font-size-body-2;
      font-style: italic;
    }

    &[disabled] {
      background: $input-disabled-background-color;
      cursor: not-allowed;

      &::placeholder {
        color: $input-disabled-placeholder-color;
      }
    }
  }

  input {
    height: $input-height;
    line-height: $input-height;
  }

  textarea {
    padding: variables.$spacing-md;
    min-height: 150px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &.is-focused {
    label {
      color: $input-focused-label-color;
    }
  }

  &.has-error {
    label {
      color: colors.$color-error;
    }
    input,
    textarea {
      background-color: color.adjust($color: colors.$color-error, $alpha: -0.95);
      border-color: color.adjust($color: colors.$color-error, $alpha: -0.4);

      &::placeholder {
        color: color.adjust($color: colors.$color-error, $alpha: -0.5);
      }
    }

    .icon,
    &.is-focused .icon {
      background-color: color.adjust($color: colors.$color-error, $alpha: -0.95);
      border-color: color.adjust($color: colors.$color-error, $alpha: -0.4);
    }
  }
}

.root {
  position: relative;
  width: 100%;

  @include text-input-base();
}

.leading-icon-interactive {
  .icon-leading {
    cursor: pointer;
  }
}

.trailing-icon-interactive {
  .icon-trailing {
    cursor: pointer;
  }
}

.has-leading-icon,
.has-trailing-icon {
  .input-container {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    input {
      width: 1%;
      flex: 1 1 auto;
    }
  }

  .icon-leading,
  .icon-trailing {
    height: $input-height;
    line-height: $input-height;
    padding: 0 variables.$spacing-md;
    border-style: solid;
    border-color: $input-border-color;
    transition: all variables.$transition-duration variables.$transition-timing-function;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.is-focused .icon {
    border-color: $input-focus-border-color;
  }
}

.has-leading-icon {
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    padding-left: 0;
  }

  .icon-leading {
    border-width: 2px 0 2px 2px;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
  }
}

.has-trailing-icon {
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
    padding-right: 0;
  }

  .icon-trailing {
    border-width: 2px 2px 2px 0;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }
}

.no-outline {
  input {
    border: none;
  }
  .icon-leading,
  .icon-trailing {
    border: none;
  }
}

.is-disabled {
  .icon-leading,
  .icon-trailing {
    background: $input-disabled-background-color;
    cursor: not-allowed;
    span {
      cursor: not-allowed;
    }
  }
}

.dark {
  label {
    color: colors.$color-white-secondary;
  }

  input,
  textarea {
    background: colors.$color-carbon-secondary;
    border-color: colors.$color-carbon-primary;
    color: colors.$color-white-primary;

    &:focus {
      border-color: colors.$color-carbon;
    }

    &::placeholder {
      color: colors.$color-white-secondary;
    }

    &[disabled] {
      background: $input-disabled-background-color;
      cursor: not-allowed;

      &::placeholder {
        color: $input-disabled-placeholder-color;
      }
    }
  }

  &.is-focused {
    label {
      color: colors.$color-white-primary;
    }

    input,
    textarea {
      background: colors.$color-carbon-secondary;
    }
  }
}
