@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

$count-colors: (
  blue: colors.$color-blue-primary,
  green: colors.$color-green-primary,
  yellow: colors.$color-yellow-primary,
  orange: colors.$color-orange-primary,
  red: colors.$color-deep-red-primary,
);

.root {
  background: colors.$color-white;
  border-radius: variables.$radius-md;
  margin-bottom: variables.$spacing-md;

  h2 {
    font-size: variables.$font-size-body-1;
    font-weight: variables.$font-weight-medium;
    padding: variables.$spacing-md;
    margin: 0;
    border-bottom: 1px solid colors.$color-black-10;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid colors.$color-black-10;
      transition: all variables.$transition-duration variables.$transition-timing-function;

      &:hover {
        background-color: colors.$color-grey-tint;
      }

      &:last-child {
        border-bottom: none;
      }

      button {
        @include button.button-reset;
        outline: none;
        text-decoration: none;
        color: colors.$color-black-90;
        padding: variables.$spacing-md;
        display: flex;
        align-items: center;
        transition: all variables.$transition-duration variables.$transition-timing-function;

        &:hover,
        &:focus {
          transform: translateX(1%);
        }
      }

      .count {
        display: flex;
        width: 48px;
        min-width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        background: colors.$color-black-30;
        color: colors.$color-white;
        margin-right: variables.$spacing-sm;
        border-radius: 90%;

        @each $color-name, $color-value in $count-colors {
          &.#{'' + $color-name} {
            background-color: $color-value;
          }
        }
      }
    }
  }
}
