@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: variables.$spacing-md;
  border-bottom: 1px solid colors.$color-grey-accent;

  .brand {
    display: flex;
    align-items: center;

    img {
      max-width: 32px;
      box-shadow: 0 2px 2px 2px colors.$color-carbon-tint;
      margin-right: variables.$spacing-md;
      border-radius: 4px;
    }

    h3 {
      margin: 0;
      font-weight: variables.$font-weight-medium;
      color: colors.$color-text;
    }
  }
}
