@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

// Input
$input-font-size: variables.$text-field-font-size;
$input-border-color: colors.$color-grey-secondary;
$input-background-color: colors.$color-white;
$input-border-radius: variables.$radius-sm;
$input-placeholder-color: colors.$color-carbon-secondary;
$input-height: 48px;

// Disabled
$input-disabled-background-color: rgba(27, 32, 33, 0.04);
$input-disabled-placeholder-color: colors.$color-black-30;

// Focused
$input-focus-border-color: colors.$color-dark-blue;
$label-font-size: variables.$font-size-caption;
$input-focused-label-color: colors.$color-black;

@mixin currency-input-base() {
  &.is-focused {
    label {
      color: $input-focused-label-color;
    }
  }
}

.root {
  position: relative;
  width: 100%;

  @include currency-input-base();
}

.leading-icon-interactive {
  .icon-leading {
    cursor: pointer;
  }
}

.trailing-icon-interactive {
  .icon-trailing {
    cursor: pointer;
  }
}

.has-leading-icon,
.has-trailing-icon {
  .input-container {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    input {
      width: 1%;
      flex: 1 1 auto;
    }
  }

  .icon-leading,
  .icon-trailing {
    height: $input-height;
    line-height: $input-height;
    padding: 0 variables.$spacing-md;
    border-style: solid;
    border-color: $input-border-color;
    transition: all variables.$transition-duration variables.$transition-timing-function;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.is-focused .icon {
    border-color: $input-focus-border-color;
  }
}

.has-leading-icon {
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    padding-left: 0;
  }

  .icon-leading {
    border-width: 2px 0 2px 2px;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
  }
}

.has-trailing-icon {
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
    padding-right: 0;
  }

  .icon-trailing {
    border-width: 2px 2px 2px 0;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }
}

[class*='TextField_is-disabled'] {
  .icon-leading,
  .icon-trailing {
    background: $input-disabled-background-color;
    cursor: not-allowed;
  }
}

[class*='TextField_has-error'] {
  .icon-leading,
  .icon-trailing {
    background-color: color.adjust($color: colors.$color-error, $alpha: -0.95);
    border-color: color.adjust($color: colors.$color-error, $alpha: -0.4);
  }
  .is-focused .icon-leading,
  .is-focused .icon-trailing {
    background-color: color.adjust($color: colors.$color-error, $alpha: -0.95);
    border-color: color.adjust($color: colors.$color-error, $alpha: -0.4);
  }
}
