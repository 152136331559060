@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.resend {
  @include button.button-reset;
  color: colors.$color-system;
}

.form {
  padding-bottom: variables.$spacing-lg;
}
