@use 'src/theme/styles/variables';

.amount-text {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.edit-button {
  cursor: pointer;
}

.edit-button-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.inline-input {
  border: 0px;
  border-bottom: 1px solid #cccccc;
  width: 120px;
  outline-width: 0;
  height: 14px;
  line-height: 14px;
}
