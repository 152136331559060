@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

// Input
$input-font-size: variables.$text-field-font-size;
$input-border-color: colors.$color-grey-secondary;
$input-background-color: colors.$color-white;
$input-border-radius: variables.$radius-sm;
$input-placeholder-color: colors.$color-carbon-secondary;
$input-height: 48px;

// Focused
$input-focus-border-color: colors.$color-dark-blue;
$label-font-size: variables.$font-size-caption;

// Label
$label-color: colors.$color-black-secondary;
$input-focused-label-color: colors.$color-black;

// Disabled
$input-disabled-background-color: rgba(27, 32, 33, 0.04);
$input-disabled-placeholder-color: colors.$color-black-30;

@mixin text-input-base() {
  label {
    display: block;
    font-size: $label-font-size;
    color: $label-color;
    margin: 4px 0;
    cursor: pointer;
    transition: all variables.$transition-duration variables.$transition-timing-function;
  }

  input {
    width: 100%;
    background: $input-background-color;
    border: 2px solid $input-border-color;
    border-radius: $input-border-radius;
    font-size: $input-font-size;
    padding: 0 variables.$spacing-md;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:focus {
      outline: none;
      border-color: $input-focus-border-color;
    }

    &::placeholder {
      color: $input-placeholder-color;
      font-size: variables.$font-size-body-2;
      font-style: italic;
    }

    &[disabled] {
      background: $input-disabled-background-color;
      cursor: not-allowed;

      &::placeholder {
        color: $input-disabled-placeholder-color;
      }
    }
  }

  input {
    height: $input-height;
    line-height: $input-height;
  }

  // &.is-focused {
  //   label {
  //     color: $input-focused-label-color;
  //   }
  // }

  &.has-error {
    label {
      color: colors.$color-error;
    }
    input {
      background-color: color.adjust($color: colors.$color-error, $alpha: -0.95);
      border-color: color.adjust($color: colors.$color-error, $alpha: -0.4);

      &::placeholder {
        color: color.adjust($color: colors.$color-error, $alpha: -0.5);
      }
    }
  }
}

.root {
  position: relative;
  width: 100%;

  @include text-input-base();
}
