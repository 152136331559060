@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

@mixin active-button() {
  background: colors.$color-grey-tint;
  color: colors.$color-black-primary;
  border-right: 4px solid colors.$color-black-secondary;
  font-weight: variables.$font-weight-bold;

  i {
    opacity: 1;
  }
}

.navigation {
  position: relative;
  height: 100%;
  width: 100%;

  nav {
    height: 100%;

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      ul {
        height: 0;
        visibility: hidden;
        opacity: 0;
        list-style: none;
        border-left: 1px dashed colors.$color-carbon-accent;
        padding: 0;

        button {
          padding: variables.$spacing-md variables.$spacing-sm variables.$spacing-md variables.$spacing-xl;
        }
      }

      li.open ul {
        margin: variables.$spacing-sm 0 variables.$spacing-sm variables.$spacing-lg;
        height: auto;
        visibility: visible;
        opacity: 1;
      }

      li.active button {
        @include active-button();
      }

      li.disabled {
        opacity: 0.5;

        button {
          cursor: not-allowed;
        }
      }

      button {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        padding: 12px variables.$spacing-sm;
        padding-right: 0;
        border-radius: variables.$radius-sm 0 0 variables.$radius-sm;
        color: colors.$color-black-secondary;
        font-size: variables.$font-size-body-1;
        cursor: pointer;
        width: 100%;
        text-align: left;
        transition: all variables.$transition-duration variables.$transition-timing-function;
        outline: none;

        i {
          margin-right: variables.$spacing-md;
          opacity: 0.5;
          transition: all variables.$transition-duration variables.$transition-timing-function;
        }

        &:hover,
        &:focus {
          color: colors.$color-black-primary;

          i {
            opacity: 1;
          }
        }

        &.active {
          @include active-button;
        }
      }
    }

    .logout {
      position: relative;
      padding: variables.$spacing-md variables.$spacing-sm;
      display: flex;
      align-items: center;
      width: 215px;
      border-top: 1px solid colors.$color-carbon-tint;

      span {
        font-size: variables.$font-size-body-1;
        margin-left: variables.$spacing-md;
        color: colors.$color-black-primary;
      }

      button {
        @include button.button-reset();
        opacity: 0.3;
        transform: scale(0.8);
        margin-left: auto;
      }
    }
  }
}

@media #{variables.$desktop} {
  .navigation {
    padding-top: variables.$spacing-sm;

    .logout {
      &.is-intersecting {
        position: absolute;
        bottom: 0;
      }
    }
  }
}
