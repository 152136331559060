@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/mixins/forms';

.root {
  position: relative;
}

.form {
  @include forms.fullHeightForm();
}

.remove-button {
  @include button.button-reset;
}

.action-cell {
  justify-content: flex-end;
}

// Quick hack to prevent title from jumping.
.search-segments-title {
  height: 70px;
  margin: 0;
  display: flex;
  align-items: center;
}
