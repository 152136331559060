@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;

  &:after {
    content: '';
    display: none;
    position: absolute;
    background: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all variables.$transition-duration variables.$transition-timing-function;
    opacity: 0.8;
  }

  &.active {
    pointer-events: none;
    &::after {
      display: block;
      background: colors.$color-background;
    }
  }

  &.active.bg-white::after {
    background: colors.$color-white;
  }

  &.active.bg-dark::after {
    background: colors.$color-bg-carbon;
  }

  &.active.gradient {
    &::after {
      background: radial-gradient(circle at center, colors.$color-background 30%, transparent, transparent 100%);
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}
