@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0;
    h4 {
      font-size: variables.$font-size-body-1;
    }
    i {
      margin-right: 0;
    }
  }

  ul {
    @include general.reset-list();
    margin-top: variables.$spacing-md;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        padding: 0 variables.$spacing-sm;

        &:first-of-type {
          padding-left: 0;
          font-size: variables.$font-size-caption;
          white-space: nowrap;
        }

        &:last-of-type {
          margin-left: auto;
          padding-right: 0;
          white-space: nowrap;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: variables.$spacing-md;

    button {
      margin-left: variables.$spacing-sm;
    }
  }
}
