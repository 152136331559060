@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    font-size: variables.$font-size-a3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
  }

  .footer {
    margin-top: 30px;
  }

  // Buttons
  .add {
    text-align: left;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-image: url('../../../../../theme/icons-v2/User/Contact-add.svg');
  }
}
