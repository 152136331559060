@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

// Input
$input-font-size: variables.$text-field-font-size;
$input-border-color: colors.$color-grey-secondary;
$input-background-color: colors.$color-white;
$input-border-radius: variables.$radius-sm;
$input-placeholder-color: colors.$color-carbon-secondary;
$input-height: 48px;

// Disabled
$input-disabled-background-color: rgba(27, 32, 33, 0.04);
$input-disabled-placeholder-color: colors.$color-black-30;

// Focused
$input-focus-border-color: colors.$color-dark-blue;
$label-font-size: variables.$font-size-caption;

.root {
  input,
  button {
    border: 2px solid $input-border-color !important;
    border-radius: $input-border-radius !important;
    height: $input-height !important;
    line-height: $input-height !important;
  }
  input {
    width: 100%;
    background: $input-background-color;
    font-size: $input-font-size !important;
    margin-left: -2px !important;
    padding: 0 variables.$spacing-md 0 variables.$spacing-sm !important;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:focus {
      outline: none !important;
      border-color: $input-focus-border-color !important;
    }

    &[disabled] {
      background: $input-disabled-background-color !important;
      cursor: not-allowed !important;
    }
  }
  button {
    border-width: 2px 0 2px 2px !important;
    border-radius: 4px 0 0 4px !important;
    padding: 0 variables.$spacing-sm !important;
  }
}
