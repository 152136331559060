@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.errors {
  color: colors.$color-red;
}

.confirmation {
  margin-top: variables.$spacing-md;

  > label {
    align-items: flex-start !important;
    font-size: variables.$font-size-caption !important;

    p:first-child {
      margin-top: 0;
    }
  }
}

.button-container {
  margin: variables.$spacing-md 0;

  > span {
    width: 100%;
  }
}
