@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/forms';

.root {
  max-width: 100%;

  h2 {
    margin-top: 0;
    margin-bottom: variables.$spacing-md;
    font-size: variables.$font-size-a2;
  }

  form {
    @include forms.form();

    button {
      margin-top: 0;
    }
  }
}

.submit-button {
  margin-top: variables.$spacing-md;
  > span {
    // display: block;
  }
}
