@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;
  display: block;
  font-style: normal;
  color: colors.$color-black-90;
  font-weight: normal;

  &:after {
    content: '';
    width: 1px;
    height: 32px;
    background-color: colors.$color-black-10;
    display: block;
    position: absolute;
    top: variables.$spacing-sm;
    right: -(variables.$spacing-sm);
  }
}
