@use 'src/theme/styles/variables';

.balance {
  display: flex;
  align-items: center;

  &-amount {
    position: relative;
  }
  &-icon {
    margin-left: auto;
    display: flex;
  }
}
