@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

$input-height: 48px;
$option-height: 56px;
$input-border-color: colors.$color-grey-secondary;

$input-focus-border-color: colors.$color-dark-blue;

$select-menu-max-height: 280px;
$select-option-border-radius: variables.$radius-sm;

$label-font-size: variables.$font-size-caption;
$label-color: colors.$color-black-60;
$input-focused-label-color: colors.$color-black;

$option-font-size: variables.$font-size-caption;

.root {
  position: relative;
  width: 100%;
  font-size: variables.$text-field-font-size;

  :global {
    .react-select__control {
      border-width: 2px;
      border-color: $input-border-color;
      line-height: $input-height;
      height: $input-height;
      transition: all variables.$transition-duration variables.$transition-timing-function;
      display: flex;
      justify-content: space-between;
      align-content: center;
      cursor: pointer;

      &.react-select__control--menu-is-open {
        box-shadow: none;
        border-color: $input-focus-border-color;
      }
    }

    .react-select__single-value--is-disabled {
      color: rgb(84, 84, 84);
    }

    label {
      display: block;
      font-size: $label-font-size;
      color: $label-color;
      margin: 4px 0;
      cursor: pointer;
      transition: all variables.$transition-duration variables.$transition-timing-function;
    }

    .react-select__menu {
      margin-top: variables.$spacing-sm;
      border-radius: variables.$radius-sm;
      padding: variables.$spacing-sm;
      border-color: $input-border-color;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
      max-height: $select-menu-max-height + 20;
      z-index: variables.$z-index-2;
    }

    // .react-select__menu-list {

    // }

    .react-select__option {
      line-height: $option-height;
      border-radius: $select-option-border-radius;
      transition: all variables.$transition-duration variables.$transition-timing-function;
      padding: 0 variables.$spacing-md;
      font-size: $option-font-size;
      cursor: pointer;

      &.react-select__option--is-focused {
        background-color: colors.$color-grey-tint;
      }

      &.react-select__option--is-selected {
        background-color: colors.$color-carbon-tint;
        color: colors.$color-black-primary;
      }
    }

    .Select.is-open > .react-select-control {
      border-radius: variables.$radius-sm;
      border-color: $input-focus-border-color;
    }
  }
}

.is-open {
  position: relative;
}

.no-outline {
  :global {
    .react-select-control {
      border: none;
    }
  }
}

.has-error {
  :global {
    label {
      color: colors.$color-error;
    }

    .react-select__control {
      background-color: color.adjust($color: colors.$color-error, $alpha: -0.95);
      border-color: color.adjust($color: colors.$color-error, $alpha: -0.4);

      .react-select__placeholder {
        color: color.adjust($color: colors.$color-error, $alpha: -0.5);
      }
    }

    .react-select__indicator {
      border-color: colors.$color-error transparent transparent;
    }
  }
}
