@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.close {
  position: absolute;
  top: variables.$spacing-md;
  right: variables.$spacing-md;

  svg {
    cursor: pointer;
  }
}

:global(.Modal_Theme--dark) .close {
  svg path {
    fill: colors.$color-white;
  }
}

:global {
  .ReactModalPortal > div {
    opacity: 0;
  }

  .ReactModalPortal .ReactModal__Overlay {
    transition: opacity variables.$transition-duration variables.$transition-timing-function;
    background: colors.$color-black-50 !important;
    z-index: variables.$z-index-5;

    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }

  .ReactModal__Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: variables.$spacing-md;

    &.Modal_Align {
      &--left {
        justify-content: flex-start;
        .ReactModal__Content {
          transition: transform variables.$transition-duration variables.$transition-timing-function;
          transform: translateX(-120%);
        }
      }
      &--right {
        justify-content: flex-end;
        .ReactModal__Content {
          transition: transform variables.$transition-duration variables.$transition-timing-function;
          transform: translateX(300%);
        }
      }
    }

    &--after-open.Modal_Align {
      &--left {
        .ReactModal__Content {
          transform: translateX(0%);
        }
      }
      &--right {
        .ReactModal__Content {
          transform: translateX(0%);
        }
      }
    }

    &--before-close.Modal_Align {
      &--left {
        .ReactModal__Content {
          transform: translateX(-120%);
        }
      }
      &--right {
        .ReactModal__Content {
          transform: translateX(300%);
        }
      }
    }
  }

  .ReactModal__Content {
    position: fixed;
    display: flex;
    flex-direction: column;
    border: 1px solid colors.$color-black-10;
    padding: variables.$spacing-lg;
    box-shadow: 0 42px 54px 30px rgba(0, 0, 0, 0.05), 0 20px 40px 10px rgba(0, 0, 0, 0.1),
      0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: variables.$radius-md;

    .Modal_Header {
      margin: -#{variables.$spacing-lg} -#{variables.$spacing-lg} 0 -#{variables.$spacing-lg};
      padding: variables.$spacing-lg;
      border-bottom: 1px solid colors.$color-black-10;

      .Modal_Title {
        margin-bottom: 0;
      }
    }

    > .Modal_Content {
      flex: 1;
    }

    &:focus {
      outline: none;
    }

    .Modal_Title {
      margin-top: 0;
    }
  }

  .Modal_Actions {
    display: flex;
    justify-content: stretch;
    border-top: 1px solid colors.$color-black-10;
    margin: variables.$spacing-md -#{variables.$spacing-lg} -#{variables.$spacing-lg} -#{variables.$spacing-lg};

    button {
      flex: 1;
      color: colors.$color-black;
      border-radius: 0;
      font-size: variables.$font-size-fine;
      font-weight: variables.$font-weight-medium;

      &:hover {
        background-color: colors.$color-black-10;
      }
    }
  }

  .Modal_Theme {
    &--light {
      background: colors.$color-white;
      color: colors.$color-light-contrast;
    }

    &--dark {
      background: colors.$color-bg-carbon;
      color: colors.$color-dark-contrast;

      .Modal_Header {
        border-color: colors.$color-white;
      }

      .Modal_Actions {
        background: colors.$color-black-primary;
        button {
          color: colors.$color-white-primary;
        }
      }
    }
  }

  .Modal_Width {
    &--sm {
      max-width: 360px;
    }

    &--md {
      max-width: 500px;
    }

    &--lg {
      max-width: 800px;
      // @todo remove temp min-width
      min-width: 800px;
    }
  }

  .Modal_Padding {
    &--sm {
      padding: 24px;
    }

    &--md {
      padding: 40px;

      .Modal_Header {
        margin: -40px -40px 0 -40px;
        padding: 40px;
        border-bottom: 1px solid colors.$color-black-10;
      }

      .Modal_Actions {
        margin: variables.$spacing-lg -40px -40px -40px;
      }
    }

    &--lg {
      padding: 50px;

      .Modal_Header {
        margin: -50px -50px 0 -50px;
        padding: 50px;
        border-bottom: 1px solid colors.$color-black-10;
      }

      .Modal_Actions {
        margin: variables.$spacing-xl -50px -50px -50px;
      }
    }
  }

  .Modal_Height {
    &--full {
      top: variables.$spacing-md;
      bottom: variables.$spacing-md;
    }
  }
}
