@use 'src/theme/styles/mixins/dropzone';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.header {
  height: 56px;
}

.info {
  margin-bottom: variables.$spacing-md;
}

.dropzone {
  @include dropzone.dropzone();
  margin-top: variables.$spacing-md;
}

.confirm-modal {
  :global {
    .Modal_Actions button:first-child {
      border-right: 1px solid colors.$color-black-10;
      background: colors.$color-green-primary;
    }
  }
}
