@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  position: relative;
  width: 100%;

  .invoice-segment {
    padding: 0 !important;
  }

  .description {
    padding-top: 0;
  }
}

.amount {
  margin-left: auto; // pull control to the end of container
  width: 230px;
}
