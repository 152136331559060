@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  display: flex;
  flex-direction: column;
  padding: variables.$spacing-md;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: variables.$font-size-fine;
  font-weight: variables.$font-weight-medium;
  letter-spacing: 1px;
  margin-bottom: variables.$spacing-md;

  i {
    background: colors.$color-blue;
    display: block;
    border-radius: 90%;
    width: 10px;
    height: 10px;
    margin-right: variables.$spacing-sm;
  }
}

.percentage {
  color: colors.$color-green;

  &.is-negative {
    color: colors.$color-red;
  }
}

.amount {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  strong {
    font-weight: variables.$font-weight-medium;
    font-size: variables.$font-size-a3;
    color: colors.$color-black;
  }
}

.actions {
  .action {
    font-size: variables.$font-size-caption;
    color: colors.$color-black-50;
    outline: none;
    transition: all variables.$transition-duration variables.$transition-timing-function;
    @include button.button-reset;
    cursor: default;

    // &:hover,
    // &:active {
    //   color: colors.$color-black;
    // }
  }
}
