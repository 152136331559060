@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  > label {
    display: block;
    font-size: variables.$font-size-caption;
    color: colors.$color-black-secondary;
    margin: 4px 0;
  }

  .radios {
    display: flex;

    > div {
      margin-right: variables.$spacing-md;
    }
  }

  &.is-stacked {
    .radios {
      flex-direction: column;

      > div {
        margin-right: 0;
        margin-bottom: variables.$spacing-md;
      }
    }
  }

  &.has-error {
    > label {
      color: colors.$color-error;
    }
  }
}
