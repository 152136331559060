@use 'src/theme/styles/colors';

.root {
  [class*='Modal_Header'] {
    margin: -24px -24px 6px -24px;
  }
}

.sub-header {
  span {
    font-weight: bold;
    text-decoration: underline;
  }
}
