@use 'src/theme/styles/variables';

.root {
  .footer {
    display: flex;
    margin-top: variables.$spacing-lg;
    justify-content: space-between;
    align-items: baseline;
    > button {
      margin-right: variables.$spacing-md;
    }
  }
}
