@use 'src/theme/styles/variables';

p.root {
  margin: variables.$spacing-sm 0;
  font-size: variables.$font-size-body-2;
}

// Enable size and weight overrides
p {
  &.size {
    &-fine {
      font-size: variables.$font-size-fine;
    }
    &-caption {
      font-size: variables.$font-size-caption;
    }
    &-body2 {
      font-size: variables.$font-size-body-2;
    }
    &-body1 {
      font-size: variables.$font-size-body-1;
    }
  }

  &.weight {
    &-heavy {
      font-weight: variables.$font-weight-bold;
    }
    &-medium {
      font-weight: variables.$font-weight-medium;
    }
    &-regular {
      font-weight: variables.$font-weight-regular;
    }
  }
}
