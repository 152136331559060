@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/grid';
@use 'src/theme/styles/flex';

.grid {
  box-sizing: border-box;
  display: flex;
  flex: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.reverse {
  flex-direction: row-reverse;
}

[class*='col-xs'] {
  box-sizing: border-box;
  flex: 0 0 auto;
}

.col-split {
  margin-left: auto;
}

@include grid.setColumnWidths('col-xs');

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

@media #{variables.$tablet} {
  [class*='col-sm'] {
    box-sizing: border-box;
    flex: 0 0 auto;
  }

  @include grid.setColumnWidths('col-sm');

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
}

@media #{variables.$desktop} {
  [class*='col-md'] {
    box-sizing: border-box;
    flex: 0 0 auto;
  }

  @include grid.setColumnWidths('col-md');

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
}
