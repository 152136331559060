@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  list-style: none;
  padding: 0;

  li {
    display: flex;
  }

  &.spacing {
    &--none {
      li {
        margin-bottom: 0;
      }
    }
    &--sm {
      li {
        margin-bottom: variables.$spacing-sm;
      }
    }
    &--md {
      li {
        margin-bottom: variables.$spacing-md;
      }
    }
    &--lg {
      li {
        margin-bottom: variables.$spacing-xl;
      }
    }
  }

  li:last-of-type {
    margin-bottom: 0;
  }
}

.label {
  font-size: variables.$font-size-caption;
  color: colors.$color-grey-primary;
  min-width: 80px;
  padding-top: 12px;
}

.value {
  color: colors.$color-black-primary;
  font-size: variables.$font-size-body-2;
  width: 100%;
  padding: 12px 0;

  p {
    margin: 0;
  }
}
button.value {
  @include button.button-reset;
  background: colors.$color-blue-tint;
  border-radius: variables.$radius-sm;
  padding: 12px variables.$spacing-md;
  word-break: break-word;
}
