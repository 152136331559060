@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  position: relative;
  width: 100%;
  // padding: 0 $spacing-lg;
}

.header {
  height: 56px;
}

.errors {
  max-width: 752px;
}

.info-box {
  margin-bottom: variables.$spacing-lg;

  button {
    @include button.button-link;
  }
}

.dimmer-loader {
  &,
  & > div {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
}

.submit {
  position: absolute;
  bottom: variables.$spacing-md;
  left: variables.$spacing-lg;
  z-index: variables.$z-index-4;
}
