@use 'sass:color';
@use 'node_modules/@carbon/layout/scss/breakpoint';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/mixins/button';
@use '../drawer';

.root {
  @include drawer.drawer;
  overflow-y: hidden;
  background: colors.$color-dark-blue-primary;

  header {
    h1 {
      margin: 0;
      padding-left: 2px;
    }

    p {
      color: colors.$color-white-secondary;
    }

    button {
      @include button.button-reset;
      color: colors.$color-white-secondary;
      font-weight: bold;
      transition: color variables.$transition-duration variables.$transition-timing-function;

      &:hover,
      &:focus {
        color: colors.$color-white-primary;
      }
    }
  }
}

.search {
  input {
    background: colors.$color-dark-blue url('../../../../../theme/icons/search-off-white.svg') no-repeat 8px center;
    border-color: transparent;
    color: colors.$color-white-primary;

    &::placeholder {
      color: colors.$color-white-secondary;
    }

    &:focus {
      background-image: url('../../../../../theme/icons/search-on-white.svg');
      border-color: transparent;
    }
  }

  button {
    right: variables.$spacing-lg;
    background-color: colors.$color-dark-blue-primary;

    &:hover,
    &:focus {
      background-color: color.adjust(colors.$color-dark-blue-primary, $lightness: -2%);
    }
  }
}

.body {
  height: 95%;
  position: relative;
  margin-left: -(variables.$spacing-md);
  margin-right: -(variables.$spacing-md);

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 80px;
    background: linear-gradient(transparent, colors.$color-dark-blue-primary);
    position: absolute;
    bottom: 0;
  }

  [class^='Table_th'] {
    color: colors.$color-white-primary;
    border-bottom: 1px solid colors.$color-black-tint;
  }

  [class*='Table_row'] {
    padding-left: 4px;
  }

  [class^='Table_root'],
  [class^='Table_tbody'],
  [class^='Table_row'] {
    background: transparent !important;
    border-top: none !important;
    color: colors.$color-white-secondary;
  }
}

.apply-filters {
  @include button.button-reset;
  position: absolute;
  bottom: variables.$spacing-lg;
  left: variables.$spacing-md;
  background-color: colors.$color-white-secondary;
  border: none;
  text-transform: uppercase;
  padding: variables.$spacing-sm;
  border-radius: variables.$radius-sm;
  font-size: variables.$font-size-caption;
  transition: all variables.$transition-duration variables.$transition-timing-function;

  &:hover,
  &:focus {
    background-color: colors.$color-white;
  }
}
