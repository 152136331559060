@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  &.is-loading {
    background: colors.$color-black-50;
    z-index: variables.$z-index-5;
  }
}
