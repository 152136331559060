@use 'sass:math';
@use '../functions';

@mixin basisWidth($val) {
  $percent: math.percentage(math.div($val, 12));
  flex-basis: $percent;
  max-width: $percent;
}

@mixin setColumnWidths($name) {
  @for $i from 1 through 12 {
    .#{$name}-#{$i} {
      @include basisWidth($i);
    }
  }
}

@mixin setColumnPadding {
  // set padding 0.1 - 1em
  @for $i from 0 through 5 {
    @for $j from 1 through 9 {
      $val1: #{$i}-#{$j};
      $val: functions.str-replace($val1, '-', '.');
      .padding-#{$i}-#{$j} {
        padding: $val + em;
      }
      .padding-h-#{$i}-#{$j} {
        padding-right: #{$i}em;
        padding-left: #{$i}em;
      }
      .padding-b-#{$i}-#{$j} {
        padding-top: #{$i}em;
        padding-bottom: #{$i}em;
      }
      .padding-t-#{$i}-#{$j} {
        padding-top: $val + em;
      }
      .padding-b-#{$i}-#{$j} {
        padding-bottom: $val + em;
      }
      .padding-l-#{$i}-#{$j} {
        padding-left: $val + em;
      }
      .padding-r-#{$i}-#{$j} {
        padding-right: $val + em;
      }
    }
  }
  // set padding 1 - 5em
  @for $i from 0 through 5 {
    .padding-#{$i} {
      padding: #{$i}em;
    }
    .padding-h-#{$i} {
      padding-right: #{$i}em;
      padding-left: #{$i}em;
    }
    .padding-v-#{$i} {
      padding-top: #{$i}em;
      padding-bottom: #{$i}em;
    }
    .padding-t-#{$i} {
      padding-top: #{$i}em;
    }
    .padding-b-#{$i} {
      padding-bottom: #{$i}em;
    }
    .padding-l-#{$i} {
      padding-left: #{$i}em;
    }
    .padding-r-#{$i} {
      padding-right: #{$i}em;
    }
    .padding-b-#{$i} {
      padding-right: #{$i}em;
    }
  }
}
