@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  .error {
    color: colors.$color-error;
  }

  .form {
    display: flex;
  }

  .input {
    flex: 1;
    margin-right: variables.$spacing-md;
  }

  .add-button {
    height: 48px;
    margin-top: 27px;
  }
}
