@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/media-query';

.table {
  background: colors.$color-bg-mercury;
  padding: variables.$spacing-lg 0;
}

.row {
  display: flex;
  flex-wrap: wrap;

  > div {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    padding: 12px;
    overflow: hidden;
    list-style: none;
    display: flex;
    align-items: center;

    @include media-query.mq(medium-screens-up) {
      &:nth-of-type(1) {
        width: 10%;
        font-size: variables.$font-size-fine;
      }
      &:nth-of-type(2) {
        width: 35%;
      }
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: 15%;
        border-left: 1px solid colors.$color-carbon-tint;
        justify-content: flex-end;
      }
    }
  }

  &--head {
    margin-bottom: variables.$spacing-lg;
    > div {
      font-size: variables.$font-size-fine;
      text-transform: uppercase;
      border: none !important;
      padding-left: variables.$spacing-md;
    }
  }

  &--balance {
    border-style: solid;
    border-color: black;
    border-width: 0 0 3px 0;

    &:last-of-type {
      border-width: 3px 0 0 0;
    }

    @include media-query.mq(medium-screens-up) {
      > div {
        &:nth-of-type(2) {
          width: 65%;
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
          font-size: variables.$font-size-body-1;
          color: colors.$color-black-primary;
        }
      }
    }
  }
}
