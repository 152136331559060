@use 'src/theme/styles/variables';

.root {
  position: fixed;
  bottom: variables.$spacing-md;
  left: variables.$spacing-md;
  width: 300px;
  z-index: 1000;

  > div {
    margin-top: variables.$spacing-md;
  }
}
