@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  button {
    @include button.button-reset;
    width: 100%;
    cursor: pointer;
    position: relative;
    background: colors.$color-white-tint;
    padding: 12px variables.$spacing-xl 12px 12px;
    display: flex;
    align-items: center;
    color: colors.$color-white;
    text-decoration: none;
    border-radius: variables.$voice-radius;
    transition: transform variables.$transition-duration variables.$transition-timing-function;

    &:after {
      content: '';
      display: block;
      background: url('../../../../../../theme/icons/chevron-right-light.svg') no-repeat center center;
      width: 30px;
      height: 30px;
      float: right;
      position: absolute;
      top: 50%;
      right: 0px;
      margin-top: -15px;
      opacity: 0.6;
    }

    &:hover,
    &:focus {
      transform: translateX(1%);
    }

    .icon {
      background: colors.$color-black-accent;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      border-radius: variables.$voice-radius-sm;

      svg {
        fill: colors.$color-white;
        path {
          fill-opacity: 1;
        }
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h4 {
        margin: 0;
        font-size: 14px;
      }
      span {
        font-size: 12px;
        color: colors.$color-white-secondary;
      }
    }
  }
}
