@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

$height: 32px;
$border-radius: variables.$radius-sm;
$font-size: 14px;
$transition: all variables.$transition-duration variables.$transition-timing-function;

.root {
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: $height;
    height: $height;
    cursor: pointer;
  }

  label {
    display: flex;
    align-items: center;
    line-height: 1.2;
    cursor: pointer;
    font-size: $font-size;
    color: colors.$color-black-60;
    transition: $transition;

    &:before {
      display: block;
      float: left;
      cursor: pointer;
      content: '';
      padding-right: variables.$spacing-sm;
      width: $height;
      height: $height;
      background: url('../../../../theme/icons/check-off.svg');
    }

    span {
      flex: 1;
    }
  }
}

.is-focused {
  position: relative;
}

.is-checked {
  label {
    color: colors.$color-black-90;
  }
  label:before {
    background: url('../../../../theme/icons/check-on.svg');
  }
  label:after {
    opacity: 1;
  }
}

.is-disabled {
  input {
    cursor: not-allowed;
  }
  label {
    color: colors.$color-black-30;
    cursor: not-allowed;
    opacity: 0.4;
  }
  label:before {
    border-color: colors.$color-black-30;
    cursor: not-allowed;
  }
}

/** @todo error styling */
.has-error {
  label {
    color: colors.$color-error;
  }
}
