@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  position: relative;
  width: 100%;
}

.invoice-type {
  width: 50%;
}

.amount {
  width: 46%;
  margin-left: auto; // pull control to the end of container
}
