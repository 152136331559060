@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  position: absolute;
  border-right: 1px solid colors.$color-carbon-tint;
  height: 100%;
  overflow-y: scroll;
  background-color: colors.$color-white;
  box-shadow: 3px 0px 9px -2px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  @include general.styled-scrollbar;
}
