@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use '../mixins';

.root {
  padding: variables.$spacing-md;
  position: relative;

  > header {
    display: flex;
    align-items: baseline;
    flex-flow: row-reverse;
    justify-content: space-between;
  }

  .header {
    @include mixins.header;
  }

  .edit {
    @include button.button-reset;
    top: variables.$spacing-sm;
    right: variables.$spacing-sm;
  }

  .meta-data {
    color: colors.$color-black-primary;
    font-size: variables.$font-size-caption;
    font-weight: variables.$font-weight-regular;
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    color: colors.$color-black-primary;
    margin-bottom: 0;
    margin-top: 0;
  }

  .sub-title {
    color: colors.$color-black-primary;
    font-size: variables.$font-size-body-2;
    font-weight: variables.$font-weight-regular;
    margin-top: 0;
  }

  .lastModified {
    position: relative;
    color: colors.$color-carbon-secondary;
  }

  section {
    border-bottom: 2px solid colors.$color-grey-accent;
    margin: variables.$spacing-lg 0;

    &:last-of-type {
      border: none;
    }

    h3 {
      color: colors.$color-black-primary;
      margin: variables.$spacing-xxl 0;
    }
  }
}
