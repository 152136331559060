@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.background,
.progress {
  fill: none;
}

.background {
  stroke: colors.$color-black-10;
}

.progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}
