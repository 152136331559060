@import 'src/theme/styles/mixins';

.root {
  button {
    @include button-reset;
  }

  svg path:last-of-type {
    fill-opacity: 0.4;
  }

  &.active {
    svg path:last-of-type {
      fill-opacity: 0.8;
    }
  }
}
