@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/forms';

.root {
  form {
    @include forms.form();
  }
}

.error {
  color: red;
}
