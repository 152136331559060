@import 'src/theme/styles/variables';

.root {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-caption;
}

.active-filters-container {
  display: flex;
  align-items: center;
  max-width: 80%;

  > span {
    font-weight: bold;
    text-transform: uppercase;
    margin-right: $spacing-sm;
  }
}

.active-filters {
  .pill {
    margin: 4px $spacing-sm 4px 0;
  }
}
