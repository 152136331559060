@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;
}

.header {
  display: flex;
  border-bottom: 1px solid colors.$color-black-10;

  // Hide switch strong tag
  div[class^='Switch_root'] {
    padding: variables.$spacing-sm 0 variables.$spacing-sm variables.$spacing-sm;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    strong {
      position: absolute;
      width: 36px;
      font-weight: 500;
    }
  }

  strong {
    flex-grow: 1;
    padding: variables.$spacing-sm;
  }

  .close {
    padding: 10px;
    cursor: pointer;
    line-height: 1;
  }
}

.body {
  display: flex;
}

// Active state

:global {
  .Popover-body {
    border-radius: variables.$radius-sm;
  }
  .Popover-active {
    div[class^='Switch_root'] {
      background-color: color.adjust($color: colors.$color-blue, $alpha: -0.6);
    }
  }
}
