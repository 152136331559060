@use 'src/theme/styles/variables';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.loading {
    cursor: progress;
  }
}

.loader {
  position: absolute;
  z-index: variables.$z-index-1;
}
