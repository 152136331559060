@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

$height: 21px;
$border-radius: 90%;
variables.$font-size: 14px;
$transition: all variables.$transition-duration variables.$transition-timing-function;

.root {
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: $height + 1;
    height: $height + 1;
    cursor: pointer;
  }

  label {
    height: $height;
    line-height: $height;
    cursor: pointer;
    font-size: variables.$font-size;
    color: colors.$color-black-60;
    transition: $transition;

    &:before {
      display: block;
      float: left;
      cursor: pointer;
      content: '';
      margin-right: 12px;
      width: $height;
      height: $height;
      background: transparent;
      border: 2px solid colors.$color-black-10;
      border-radius: $border-radius;
    }

    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 9px;
      height: 5px;
      background: transparent;
      top: 7px;
      left: 6px;
      border: 2px solid colors.$color-white;
      border-top: none;
      border-right: none;
      transition: $transition;

      transform: rotate(-45deg);
    }
  }
}

.is-focused {
  position: relative;
}

.is-checked {
  label {
    color: colors.$color-black-90;
  }
  label:before {
    background: colors.$color-black;
    border-color: colors.$color-black;
  }
  label:after {
    opacity: 1;
  }
}

.is-disabled {
  input {
    cursor: not-allowed;
  }
  label {
    color: colors.$color-black-30;
    cursor: not-allowed;
  }
  label:before {
    border-color: colors.$color-black-30;
    cursor: not-allowed;
  }
}

/** @todo error styling */
.has-error {
  color: colors.$color-error;
}
