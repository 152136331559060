@use 'src/theme/styles/variables';

.root {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .fabs {
    position: fixed;
    bottom: variables.$spacing-md;
    right: variables.$spacing-md;
    z-index: variables.$z-index-4;
  }
}
