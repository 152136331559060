@use 'src/theme/styles/variables';

.root {
  display: flex;
  flex-direction: column;

  .subtext {
    font-size: variables.$font-size-caption;
  }
}
