@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  width: 100%;
  // @include general.styled-scrollbar;
  section {
    padding: variables.$spacing-lg;
    &:not(:first-of-type) {
      border-bottom: 1px solid colors.$color-carbon-tint;
    }
  }
}

.button-container {
  display: block;
}

.container {
  max-width: 752px;
}

.theme-preview {
  margin: variables.$spacing-xl 0;
}

.errors {
  color: colors.$color-red;
}
