@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: variables.$spacing-sm;
  position: relative;

  .details {
    width: 100%;
    padding-right: variables.$spacing-md;

    > div {
      display: flex;
      justify-content: space-between;
    }

    .transfer-toggle label {
      color: colors.$color-black-60;
      strong {
        font-weight: variables.$font-weight-bold;
      }
    }
  }

  .input-wrapper {
    width: 220px;
    min-width: 220px;
    align-self: center;
    input::placeholder {
      font-size: variables.$font-size-caption;
    }
  }

  .beneficiary-text {
    span {
      opacity: 0.8;
      font-size: variables.$font-size-caption;
    }
  }

  .meta {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > div[class*='CurrencyField'] {
    margin-left: auto;
    width: 230px;
  }
}
