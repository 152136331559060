@use 'src/theme/styles/variables';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  :global {
    .rt-tr-group {
      background: url('../../../../../../theme/icons/right-arrow-off.svg') no-repeat right center;
    }
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .datepicker-wrapper {
    display: flex;
    padding: variables.$spacing-xxl 0 variables.$spacing-md;
    max-width: 350px;
  }
}
