@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root,
.loader {
  display: flex;
  flex-direction: column;
  flex: 1;
}

div[class*='AgenciesTable_root'] {
  div[class^='Table_td'] {
    overflow: visible !important;
  }
}

.deactivate,
.activate {
  padding: variables.$spacing-sm;
}

.deactivate {
  background: colors.$color-error-secondary;
  &:hover,
  &:focus {
    background: colors.$color-error-primary;
  }
}

.activate {
  background: colors.$color-success-secondary;
  &:hover,
  &:focus {
    background: colors.$color-success-primary;
  }
}
