@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  position: relative;

  form footer {
    display: flex;
    border-top: 1px solid colors.$color-black-10;
    margin: variables.$spacing-md -#{variables.$spacing-lg};
    padding: variables.$spacing-xl variables.$spacing-lg;
    justify-content: flex-end;

    [class*='Button_root'] {
      margin-left: variables.$spacing-md;
    }
  }
}

.header {
  display: flex;
  border-bottom: 1px solid colors.$color-black-10;

  &-info {
    flex-grow: 1;
  }

  &-controls {
    position: relative;
  }
}

.invoice-number {
  font-size: variables.$font-size;
  text-transform: uppercase;
}

.due-date {
  margin-left: auto;
  width: 40%;
}

.deleted-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: variables.$spacing-md;

  button {
    font-size: variables.$font-size-caption;
  }
}

.invoice-data {
  font: inherit;
}

.documents {
  margin-top: 0;

  [class*='Header_root'] {
    padding: variables.$spacing-md;
  }
}
