@use 'src/theme/styles/colors';

.draft-invoice-filters {
  li[class*='Filters_active'] {
    &:nth-child(1) button {
      border-bottom-color: colors.$color-orange-secondary;
      > span {
        background: colors.$color-orange;
      }
    }
    &:nth-child(2) button {
      border-bottom-color: colors.$color-green-secondary;
      > span {
        background: colors.$color-green;
      }
    }
  }
}
