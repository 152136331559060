@import 'src/theme/styles/variables';
@import 'src/theme/styles/mixins';

.root {
  position: relative;
}

.form {
  @include fullHeightForm();
}
