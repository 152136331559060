@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.card {
  padding: variables.$spacing-md;
  display: flex;
  flex-direction: column;
  flex: 1;

  ul[class*='InfoList_root'] {
    li span {
      min-width: 120px;
    }

    button {
      width: auto;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 0 variables.$spacing-md;
  border-bottom: 1px solid colors.$color-carbon-accent;

  .close {
    @include button.button-reset;
    background: url('../../../../theme/icons/arrow-left.svg') no-repeat left center;
    padding: 27px variables.$spacing-md;
    outline: none;
  }

  .menu {
    padding-left: 10px;
    margin-left: 20px;
  }
}

.title-text {
  margin-bottom: variables.$spacing-lg;

  h2 {
    font-size: variables.$font-size-a2;
    color: colors.$color-text;
    margin-bottom: 0;
  }

  span {
    margin-right: variables.$spacing-lg;
  }
}

.info {
  margin-bottom: variables.$spacing-lg;
}

.table {
  margin-top: variables.$spacing-md;
}
