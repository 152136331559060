@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/forms';

.root {
  position: relative;
}

.form {
  @include forms.fullHeightForm();

  .add-invoice {
    &:hover {
      border-color: color.adjust($color: colors.$color-system-primary, $alpha: -0.5);
    }
  }
}

.divider-center-label {
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    display: inline-block;
    position: relative;
    padding: 0 1rem;
    top: -22px;
    margin: 0;
    background-color: #fff;
  }
}
