@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/colors';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 96px;
}

.property-select {
  margin-top: 1.5rem;
  div[class^='react-select__value-container'] {
    flex-wrap: initial;
  }
  div[class^='react-select__multi-value'] {
    line-height: 20px;
  }
}

.action-cell {
  max-width: 40px;
  justify-content: flex-end;
  button {
    @include button.button-reset;
    opacity: 0.7;
    transition: opacity variables.$transition-duration variables.$transition-timing-function;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
