@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;
  display: inline-block;
  background-color: colors.$color-black;
  height: 56px;
  width: 56px;
  border-radius: 0 12px 12px 12px;
  opacity: 0.5;
  color: colors.$color-white;

  svg {
    position: absolute;
    top: 9px;
  }
}
