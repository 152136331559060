@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/mixins/general';

.root {
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    width: 160px;
    height: 56px;
    background-color: rgba(5, 109, 186, 0.88);
    position: absolute;
    border-radius: 90px;
    left: -4px;
    box-shadow: 0 4px 9px colors.$color-black-accent;
  }

  .shortcuts,
  .robin,
  .segments {
    @include general.ir();
    @include button.button-reset;
    outline: none;

    &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .shortcuts,
  .segments {
    background-color: colors.$color-dark-blue-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 90%;
    margin-right: 4px;
    z-index: variables.$z-index-3;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:hover,
    &:focus {
      background-color: color.adjust(colors.$color-dark-blue, $lightness: -5%);
    }
  }

  .segments {
    position: relative;
  }

  .robin {
    background: url('../../../../../theme/images/robin-button.svg') no-repeat;
    width: 64px;
    height: 64px;
    display: block;
    z-index: variables.$z-index-3;
    transition: transform 0.2s ease-in-out;
  }

  .notifications-count {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: variables.$z-index-4;
    span {
      background: colors.$color-red-primary;
      color: white;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    }
  }

  .segment-filters-count {
    position: absolute;
    bottom: -8px;
    right: 0;
    z-index: variables.$z-index-4;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      min-width: 20px;
      background: color.adjust(colors.$color-dark-blue, $lightness: -10%);
      color: white;
    }
  }

  &.shortcuts-drawer-open,
  &.segments-drawer-open {
    &:before,
    .shortcuts,
    .segments {
      display: none;
    }
  }

  &.robin-drawer-open {
    .robin {
      transform: rotate(180deg);
    }
  }
}
