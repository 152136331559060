@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  width: 100%;

  :global {
    .react-select__menu {
      padding: 0;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
      z-index: variables.$z-index-5;
    }
  }

  .option {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: variables.$spacing-md !important;
    cursor: pointer !important;

    div:first-of-type {
      margin-right: variables.$spacing-md;
    }

    &:hover,
    &--is-focused {
      background-color: colors.$color-grey-tint !important;
    }

    &--is-selected {
      background-color: colors.$color-carbon-tint !important;
      color: colors.$color-black-primary !important;
    }
  }

  .info {
    width: 70%;
  }
}
