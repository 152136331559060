@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.body {
  background-color: colors.$color-grey-tint;
  border: 1px solid colors.$color-grey-accent;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
  border-radius: 2px;
  height: 100%;
  display: flex;
  flex-direction: column;

  figure {
    background-color: colors.$color-body-background;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0;
    padding: 2rem;
    min-height: 150px;
    max-height: 150px;
  }

  img {
    max-height: 40px;
  }

  .content {
    padding: 3rem 3.5rem 0;
    border-top: 1px solid colors.$color-grey-accent;

    h3,
    p {
      color: colors.$color-black;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .actions {
    margin-top: auto;
    padding: 1rem 3.5rem 4rem;
    button {
      border-radius: 50px;
      padding: 0.3rem;
      border: 1px solid colors.$color-body-background;
      max-width: 40px;
      max-height: 40px;
      opacity: 0.7;

      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}
