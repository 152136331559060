@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  background: colors.$color-white;
  border-radius: variables.$radius-md;
  margin-bottom: variables.$spacing-md;

  button {
    outline: none;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: variables.$spacing-md;
    border-bottom: 1px solid colors.$color-black-10;

    h2 {
      font-size: variables.$font-size-body-1;
      font-weight: variables.$font-weight-medium;
      margin: 0;
    }
  }

  .wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: variables.$spacing-md;

    .account {
      .label {
        text-transform: uppercase;
        color: colors.$color-black-50;
        font-size: variables.$font-size-fine;
      }
    }
  }

  .statement-download {
    border-top: 1px solid colors.$color-black-10;
    padding: variables.$spacing-md;
  }
}
