@use 'src/theme/styles/colors';

.root {
  [class*='Modal_Header'] {
    margin: -24px -24px 14px -24px;
  }
  [class*='Checkbox_root'] {
    margin-left: -0.8rem;
    label {
      color: colors.$color-white;
      span {
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
  [class*='Checkbox_is-checked'] {
    label {
      color: colors.$color-white;
    }
    label:before {
      background: url('../../../../theme/icons/check-on-light.svg');
    }
  }
  [class*='Modal_Actions'] > button:first-child {
    background: colors.$color-success-secondary;
  }
}

.sub-header {
  span {
    font-weight: bold;
    text-decoration: underline;
  }
}
