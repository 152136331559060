@use 'src/theme/styles/variables';

$field-bounds: variables.$spacing-sm;

.root {
  padding: $field-bounds 0;
}

.pad-checkbox {
  div[class*='Checkbox_root'] {
    margin-top: 24px;
  }
}
