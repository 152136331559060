@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.form {
  position: relative;

  .heading {
    color: colors.$color-black-primary;
    margin-top: 0;
  }
}

.body {
  position: relative;

  > section {
    background: colors.$color-grey-tint;
    border-bottom: 2px solid colors.$color-carbon-tint;
    margin: 0 -#{variables.$spacing-lg};
    padding: variables.$spacing-xl variables.$spacing-lg;

    &:nth-child(odd) {
      background: colors.$color-white;
    }

    &:last-of-type {
      border: none;
    }
  }
}

.footer {
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -#{variables.$spacing-lg};
  padding: variables.$spacing-xl;

  button {
    margin-right: variables.$spacing-sm;
  }
}
