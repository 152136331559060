@use 'src/theme/styles/mixins/button';

.search {
  content: '';
}

.root {
  position: relative;
}

.remove-button {
  @include button.button-reset;
}

.action-cell {
  justify-content: flex-end;
}
