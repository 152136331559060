@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/functions';
@use 'src/theme/styles/mixins/general';
@use 'src/theme/styles/mixins/button';

.root {
  ul {
    @include general.reset-list();
    margin-top: variables.$spacing-md;

    li {
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-position: 0 5px;
      position: relative;
      margin: 0;

      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: colors.$color-dark-blue-primary;
        margin-right: variables.$spacing-md;
        border-radius: 90px;
      }

      button {
        @include button.button-reset();
        outline: 0;
        padding: 4px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: colors.$color-text-secondary;
        transition: all variables.$transition-duration variables.$transition-timing-function;

        &:hover,
        &:focus {
          color: colors.$color-text;
        }
      }

      .description {
        display: flex;
        flex-direction: column;
      }
      .amount {
        margin-left: auto;
      }
    }

    // Invoice Status Modifiers
    li.status {
      &-Short:before {
        background: colors.$color-yellow;
      }
      &-NotSet:before {
        background: colors.$color-orange;
      }
      &-Overdue:before {
        background: colors.$color-red;
      }
      &-Ready:before {
        background: colors.$color-green;
      }
    }
  }
  .closing-balance {
    display: flex;
    margin-top: variables.$spacing-md;
    .amount {
      font-size: variables.$font-size-body-1;
      font-weight: variables.$font-weight-medium;
      color: colors.$color-black-primary;
      margin-left: auto;
    }
  }
}
