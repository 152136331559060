@use 'colors';

$prefix: 'rbn' !default;

////-- Paths
$font_path: '../fonts' !default;

////-- Sizing
$unit: 1rem !default;
$size-unit: rem !default;
$em-base: 10 !default;

////-- Typography
$preferred-font: 'Inter', sans-serif;

$font-size: 1rem !default;
$font-size-a1: 3.2 * $unit !default;
$font-size-a2: 2.6 * $unit !default;
$font-size-a3: 2 * $unit !default;
$font-size-body-1: 1.6 * $unit !default;
$font-size-body-2: 1.4 * $unit !default;
$font-size-caption: 1.2 * $unit !default;
$font-size-fine: $unit !default;

$font-weight-thin: 200 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

////-- Forms
$text-field-font-size: $font-size-body-2 !default;

////-- Spacing
$spacing-base: 8 !default;
$spacing-sm: #{$spacing-base}px !default;
$spacing-md: #{$spacing-base * 2}px !default;
$spacing-lg: #{$spacing-base * 3}px !default;
$spacing-xl: #{$spacing-base * 4}px !default;
$spacing-xxl: #{$spacing-base * 5}px !default;
$spacing-xxxl: #{$spacing-base * 6}px !default;

////-- Radius
$radius-base: 4 !default;
$radius-sm: #{$radius-base}px !default;
$radius-md: #{$radius-base * 2}px !default;
$radius-lg: #{$radius-base * 3}px !default;
$voice-radius: 12px 12px 0 12px !default;
$voice-radius-sm: 8px 8px 0 8px !default;
$drawer-radius: 8px 8px 48px 8px !default;

////-- Shadows
$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

////-- Indexes
$z-index-1: 100 !default;
$z-index-2: 200 !default;
$z-index-3: 300 !default;
$z-index-4: 400 !default;
$z-index-5: 500 !default;

////-- Animation
$transition-duration: 280ms !default;
$transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;

////-- Media queries
$phone: 'screen and (max-width: 767px)';
$tablet: 'screen and (min-width: 767px) and (max-width: 1023px)';
$desktop: 'screen and (min-width: 1024px)';

// These queries should match up with those in `src/views/hooks/useBreakpoints.js`
$sm: 'screen and (min-width: 640px)';
$md: 'screen and (min-width: 767px)';
$xxxl: 'screen and (min-width: 1600px)';
$xxxxl: 'screen and (min-width: 2000px)';

// Breakpoints
$break-small: 375px !default;
$break-medium: 768px !default;
$break-large: 1024px !default;
$break-larger: 1280px !default;
$break-wide: 2560px !default;
