@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/forms';

.root {
  position: relative;
}

.radio-group {
  margin-bottom: 24px;
}

.form {
  @include forms.fullHeightForm();

  .footer {
    > button,
    > a {
      margin-right: variables.$spacing-sm;
    }
  }
}

[class*='FormLoader_button-wrapper'] {
  margin-top: 2rem;
  display: inline-block;
  width: auto;
}
