@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;

  &.edit-form {
    section:first-of-type {
      padding-top: 0;
    }
    :global {
      .react-tabs__tab-list {
        display: none;
      }
    }
  }

  .heading {
    color: colors.$color-black-primary;
  }
}
