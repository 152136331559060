@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;

  .errors {
    color: colors.$color-red;
  }

  .description {
    textarea {
      min-height: 80px;
    }
  }
}
