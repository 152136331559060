@use 'src/theme/styles/variables';

h1.root {
  margin: variables.$spacing-xl 0;
  font-size: variables.$font-size-a1;
}

h2.root {
  margin: variables.$spacing-lg 0;
  font-size: variables.$font-size-a2;
}

h3.root {
  margin: variables.$spacing-md 0;
  font-size: variables.$font-size-a3;
}

// Enable size, weight and margin overrides
h1,
h2,
h3,
h4 {
  &.size {
    &-a1 {
      font-size: variables.$font-size-a1;
    }
    &-a2 {
      font-size: variables.$font-size-a2;
    }
    &-a3 {
      font-size: variables.$font-size-a3;
    }
  }

  &.weight {
    &-heavy {
      font-weight: variables.$font-weight-bold;
    }
    &-medium {
      font-weight: variables.$font-weight-medium;
    }
    &-regular {
      font-weight: variables.$font-weight-regular;
    }
  }

  &.spacing {
    &-large {
      margin: variables.$spacing-xl 0;
    }
    &-medium {
      margin: variables.$spacing-lg 0;
    }
    &-regular {
      margin: variables.$spacing-md 0;
    }
    &-small {
      margin: variables.$spacing-sm 0;
    }
  }
}
