@use 'src/theme/styles/colors';

.root {
  [class*='Modal_Header'] {
    margin: -24px -24px 14px -24px;
  }
  [class*='Modal_Actions'] > button:first-child {
    background: colors.$color-success-secondary;
  }
}

.sub-header {
  span {
    font-weight: bold;
    text-decoration: underline;
  }
}
