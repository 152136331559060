@use 'sass:color';
@function get-primary-color($color) {
  @return color.adjust($color, $alpha: -0.04);
}

@function get-secondary-color($color) {
  @return color.adjust($color, $alpha: -0.36);
}

@function get-accent-color($color) {
  @return color.adjust($color, $alpha: -0.76);
}

@function get-tint-color($color) {
  @return color.adjust($color, $alpha: -0.92);
}

$color-pink: rgba(198, 36, 130, 1) !default;
$color-pink-primary: get-primary-color($color-pink) !default;
$color-pink-secondary: get-secondary-color($color-pink) !default;
$color-pink-accent: get-accent-color($color-pink) !default;
$color-pink-tint: get-tint-color($color-pink) !default;

$color-purple: rgba(146, 25, 226, 1) !default;
$color-purple-primary: get-primary-color($color-purple) !default;
$color-purple-secondary: get-secondary-color($color-purple) !default;
$color-purple-accent: get-accent-color($color-purple) !default;
$color-purple-tint: get-tint-color($color-purple) !default;

$color-dark-blue: rgba(5, 109, 186, 1) !default;
$color-dark-blue-primary: get-primary-color($color-dark-blue) !default;
$color-dark-blue-secondary: get-secondary-color($color-dark-blue) !default;
$color-dark-blue-accent: get-accent-color($color-dark-blue) !default;
$color-dark-blue-tint: get-tint-color($color-dark-blue) !default;

$color-blue: rgba(16, 180, 213, 1) !default;
$color-blue-primary: get-primary-color($color-blue) !default;
$color-blue-secondary: get-secondary-color($color-blue) !default;
$color-blue-accent: get-accent-color($color-blue) !default;
$color-blue-tint: get-tint-color($color-blue) !default;

$color-teal: rgba(37, 211, 172, 1) !default;
$color-teal-primary: get-primary-color($color-teal) !default;
$color-teal-secondary: get-secondary-color($color-teal) !default;
$color-teal-accent: get-accent-color($color-teal) !default;
$color-teal-tint: get-tint-color($color-teal) !default;

$color-green: rgba(18, 130, 105, 1) !default;
$color-green-primary: get-primary-color($color-green) !default;
$color-green-secondary: get-secondary-color($color-green) !default;
$color-green-accent: get-accent-color($color-green) !default;
$color-green-tint: get-tint-color($color-green) !default;

$color-yellow: rgba(247, 183, 53, 1) !default;
$color-yellow-primary: get-primary-color($color-yellow) !default;
$color-yellow-secondary: get-secondary-color($color-yellow) !default;
$color-yellow-accent: get-accent-color($color-yellow) !default;
$color-yellow-tint: get-tint-color($color-yellow) !default;

$color-orange: rgba(255, 109, 33, 1) !default;
$color-orange-primary: get-primary-color($color-orange) !default;
$color-orange-secondary: get-secondary-color($color-orange) !default;
$color-orange-accent: get-accent-color($color-orange) !default;
$color-orange-tint: get-tint-color($color-orange) !default;

$color-red: rgba(255, 25, 0, 1) !default;
$color-red-primary: get-primary-color($color-red) !default;
$color-red-secondary: get-secondary-color($color-red) !default;
$color-red-accent: get-accent-color($color-red) !default;
$color-red-tint: get-tint-color($color-red) !default;

$color-deep-red: rgba(235, 0, 24, 1) !default;
$color-deep-red-primary: get-primary-color($color-deep-red) !default;
$color-deep-red-secondary: get-secondary-color($color-deep-red) !default;
$color-deep-red-accent: get-accent-color($color-deep-red) !default;
$color-deep-red-tint: get-tint-color($color-deep-red) !default;

$color-system: $color-dark-blue !default;
$color-system-primary: $color-dark-blue-primary !default;
$color-system-secondary: $color-dark-blue-secondary !default;
$color-system-accent: $color-dark-blue-accent !default;
$color-system-tint: $color-dark-blue-tint !default;

////-- Monochrome Colors
$color-bg-mercury: rgba(240, 238, 240, 1);
$color-bg-carbon: rgba(20, 20, 20, 1);

$color-black: rgba(0, 0, 0, 1) !default;
$color-black-primary: get-primary-color($color-black) !default;
$color-black-secondary: get-secondary-color($color-black) !default;
$color-black-accent: get-accent-color($color-black) !default;
$color-black-tint: get-tint-color($color-black) !default;

////-- @todo deprecate!!!
$color-black-90: color.adjust($color-black, $alpha: -0.1) !default;
$color-black-80: color.adjust($color-black, $alpha: -0.2) !default;
$color-black-70: color.adjust($color-black, $alpha: -0.3) !default;
$color-black-60: color.adjust($color-black, $alpha: -0.4) !default;
$color-black-50: color.adjust($color-black, $alpha: -0.5) !default;
$color-black-40: color.adjust($color-black, $alpha: -0.6) !default;
$color-black-30: color.adjust($color-black, $alpha: -0.7) !default;
$color-black-20: color.adjust($color-black, $alpha: -0.8) !default;
$color-black-10: color.adjust($color-black, $alpha: -0.9) !default;
$color-black-05: color.adjust($color-black, $alpha: -0.95) !default;

$color-carbon: rgba(64, 63, 63, 1) !default;
$color-carbon-primary: get-primary-color($color-carbon) !default;
$color-carbon-secondary: get-secondary-color($color-carbon) !default;
$color-carbon-accent: get-accent-color($color-carbon) !default;
$color-carbon-tint: get-tint-color($color-carbon) !default;

$color-grey: rgba(178, 178, 178, 1) !default;
$color-grey-primary: get-primary-color($color-grey) !default;
$color-grey-secondary: get-secondary-color($color-grey) !default;
$color-grey-accent: get-accent-color($color-grey) !default;
$color-grey-tint: get-tint-color($color-grey) !default;

$color-white: rgba(255, 255, 255, 1);
$color-white-primary: get-primary-color($color-white) !default;
$color-white-secondary: get-secondary-color($color-white) !default;
$color-white-accent: get-accent-color($color-white) !default;
$color-white-tint: get-tint-color($color-white) !default;

////-- @todo deprecate!!!
$color-white-90: color.adjust($color-white, $alpha: -0.1) !default;
$color-white-80: color.adjust($color-white, $alpha: -0.2) !default;
$color-white-70: color.adjust($color-white, $alpha: -0.3) !default;
$color-white-60: color.adjust($color-white, $alpha: -0.4) !default;
$color-white-50: color.adjust($color-white, $alpha: -0.5) !default;
$color-white-40: color.adjust($color-white, $alpha: -0.6) !default;
$color-white-30: color.adjust($color-white, $alpha: -0.7) !default;
$color-white-20: color.adjust($color-white, $alpha: -0.8) !default;
$color-white-10: color.adjust($color-white, $alpha: -0.9) !default;

////-- Contrast Colors
$color-dark-contrast: $color-white !default;
$color-light-contrast: $color-black !default;

////-- UI Colors
$color-error: rgba(240, 30, 56, 1) !default;
$color-error-primary: get-primary-color($color-error) !default;
$color-error-secondary: get-secondary-color($color-error) !default;
$color-error-accent: get-accent-color($color-error) !default;
$color-error-tint: get-tint-color($color-error) !default;

$color-success: rgba(0, 182, 99, 1) !default;
$color-success-primary: get-primary-color($color-success) !default;
$color-success-secondary: get-secondary-color($color-success) !default;
$color-success-accent: get-accent-color($color-success) !default;
$color-success-tint: get-tint-color($color-success) !default;

$color-info: rgba(24, 103, 249, 1) !default;
$color-info-primary: get-primary-color($color-info) !default;
$color-info-secondary: get-secondary-color($color-info) !default;
$color-info-accent: get-accent-color($color-info) !default;
$color-info-tint: get-tint-color($color-info) !default;

$color-warning: rgba(255, 241, 28, 1) !default;
$color-warning-primary: get-primary-color($color-warning) !default;
$color-warning-secondary: get-secondary-color($color-warning) !default;
$color-warning-accent: get-accent-color($color-warning) !default;
$color-warning-tint: get-tint-color($color-warning) !default;

////-- Colors named for easy reference - branding colours, headings etc
$color-body-background: rgba(250, 250, 250, 1);
$color-divider: $color-black-10 !default;
$color-background: #fafafa !default;
$color-primary: $color-blue !default;
$color-primary-hover: color.adjust($color-blue, $lightness: -15%) !default;
$color-primary-contrast: $color-dark-contrast !default;
$color-secondary: $color-system-secondary !default;
$color-secondary-hover: color.adjust($color-secondary, $lightness: -15%);
$color-secondary-contrast: $color-white !default;
$color-text: $color-black-90 !default;
$color-text-hint: $color-black-70 !default;
$color-text-secondary: $color-black-60 !default;
$color-text-disabled: $color-text-secondary !default;
$color-text-error: $color-red !default;
$color-button-icon: color.adjust($color-primary, $alpha: -0.2) !default;
$color-button-icon-focus: $color-primary !default;
$color-link: $color-blue;
$color-link-hover: color.adjust($color-blue, $alpha: -0.4);
$color-icon: color.adjust($color-primary, $alpha: -0.5);
$color-icon-hover: $color-primary;
