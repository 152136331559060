@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  background: color.adjust(colors.$color-grey-tint, $lightness: -20%);
  border-radius: variables.$radius-md;
  padding: variables.$spacing-md;
  height: 100%;

  > section {
    padding: variables.$spacing-md 0;
    border-bottom: 2px solid colors.$color-carbon-tint;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: variables.$font-size-body-1;
      font-weight: variables.$font-weight-medium;
      color: colors.$color-black-primary;
    }

    &.account-selector {
      margin: 0 -#{variables.$spacing-md};
      > div {
        padding: 0 variables.$spacing-md;
      }
    }
  }
}
