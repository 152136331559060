@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  padding: variables.$spacing-md;
  position: relative;

  .edit {
    @include button.button-reset;
    position: absolute;
    top: variables.$spacing-sm;
    right: variables.$spacing-sm;
  }

  .name {
    color: colors.$color-black-primary;
    margin-bottom: 0;
  }

  .sub-title {
    color: colors.$color-black-primary;
    font-size: variables.$font-size-body-2;
    font-weight: variables.$font-weight-regular;
    margin-top: 0;
  }

  .lastModified {
    position: relative;
    color: colors.$color-carbon-secondary;
  }

  section {
    border-bottom: 2px solid colors.$color-grey-accent;

    &:last-of-type {
      border: none;
    }

    h3 {
      color: colors.$color-black-primary;
      margin: variables.$spacing-xxl 0;
    }

    li {
      margin-bottom: variables.$spacing-sm !important;
    }
  }
}
