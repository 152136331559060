@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';
@use 'src/theme/styles/functions';

$height: 24px;
$border-radius: variables.$radius-sm;
$font-size: 14px;
$transition: all variables.$transition-duration variables.$transition-timing-function;

.container {
  position: relative;
}

.root {
  position: relative;
  display: block;
  overflow: visible;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  > label {
    float: left;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;

    > span {
      display: block;
      float: left;
      overflow: visible;
      position: relative;
      top: functions.size(4);
      width: functions.size(24);
      height: functions.size(14);
      min-height: auto;
      border-radius: functions.size(16);
      background: rgba(0, 0, 0, 0.26);
      margin-right: variables.$spacing-sm;
    }

    > strong {
      // label text
      float: right;
      font-weight: normal;
    }
  }

  span span {
    position: absolute;
    clip: rect(0 0 0 0);
  }

  input {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  input + label {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    padding: 0 functions.size(8);
    margin: 0;

    text-align: center;
  }

  button {
    border: none;
    display: block;
    position: absolute;
    padding: 0;
    z-index: 1;
    height: 100%;
    outline: none;
    cursor: pointer;

    transform: translateX(functions.size(2));
    top: functions.size(2);
    // left: size(2);
    width: functions.size(10);
    height: functions.size(10);
    border-radius: 50%;
    background: #fafafa;
    box-shadow: 0 functions.size(2) functions.size(2) 0 rgba(0, 0, 0, 0.14),
      0 functions.size(3) functions.size(2) functions.size(-2) rgba(0, 0, 0, 0.2),
      0 functions.size(2) functions.size(4) 0 rgba(0, 0, 0, 0.12);

    transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  }

  /* simulate default browser focus outlines on the switch,
   * when the inputs are focused.
   */
  input:focus ~ span a,
  input:focus + label {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;

    /* Chrome/Opera gets its native focus styles.
     */
    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
    }
  }

  input:checked ~ span a {
    right: 2px;
    left: auto;

    background: #3f51b5;
    box-shadow: 0 functions.size(3) functions.size(4) 0 rgba(0, 0, 0, 0.14),
      0 functions.size(3) functions.size(3) functions.size(-2) rgba(0, 0, 0, 0.2),
      0 functions.size(1) functions.size(6) 0 rgba(0, 0, 0, 0.12);
  }

  input:checked ~ span {
    background: rgba(63, 81, 181, 0.5);
  }

  /* 2 items
   */
  label:nth-child(2):nth-last-child(4),
  label:nth-child(2):nth-last-child(4) ~ label,
  label:nth-child(2):nth-last-child(4) ~ a {
    width: 50%;
  }

  label:nth-child(2):nth-last-child(4) ~ input:checked:nth-child(3) + label ~ a {
    left: 50%;
  }

  /* 3 items
   */
  label:nth-child(2):nth-last-child(6),
  label:nth-child(2):nth-last-child(6) ~ label,
  label:nth-child(2):nth-last-child(6) ~ a {
    width: 33.33%;
  }

  label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(3) + label ~ a {
    left: 33.33%;
  }

  label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(5) + label ~ a {
    left: 66.66%;
  }

  /* 4 items
   */
  label:nth-child(2):nth-last-child(8),
  label:nth-child(2):nth-last-child(8) ~ label,
  label:nth-child(2):nth-last-child(8) ~ a {
    width: 25%;
  }

  label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(3) + label ~ a {
    left: 25%;
  }

  label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(5) + label ~ a {
    left: 50%;
  }

  label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(7) + label ~ a {
    left: 75%;
  }

  /* 5 items
   */
  label:nth-child(2):nth-last-child(10),
  label:nth-child(2):nth-last-child(10) ~ label,
  label:nth-child(2):nth-last-child(10) ~ a {
    width: 20%;
  }

  label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(3) + label ~ a {
    left: 20%;
  }

  label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(5) + label ~ a {
    left: 40%;
  }

  label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(7) + label ~ a {
    left: 60%;
  }

  label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(9) + label ~ a {
    left: 80%;
  }

  /* 6 items
   */
  label:nth-child(2):nth-last-child(12),
  label:nth-child(2):nth-last-child(12) ~ label,
  label:nth-child(2):nth-last-child(12) ~ a {
    width: 16.6%;
  }

  label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(3) + label ~ a {
    left: 16.6%;
  }

  label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(5) + label ~ a {
    left: 33.2%;
  }

  label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(7) + label ~ a {
    left: 49.8%;
  }

  label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(9) + label ~ a {
    left: 66.4%;
  }

  label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(11) + label ~ a {
    left: 83%;
  }
}

/* Bugfix for older Webkit, including mobile Webkit. Adapted from
 * http://css-tricks.com/webkit-sibling-bug/
 */
@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: functions.size(1280)) {
  .root {
    -webkit-animation: webkitSiblingBugfix infinite 1s;
  }
}

@-webkit-keyframes webkitSiblingBugfix {
  from {
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.is-focused {
  position: relative;
}

.is-checked {
  label {
    color: colors.$color-black-90;
  }
  label:before {
    background: colors.$color-white;
    border-color: colors.$color-black-60;
  }
  label:after {
    opacity: 1;
  }

  button {
    transform: translateX(functions.size(12));
  }
}

.is-disabled {
  input {
    cursor: not-allowed;
  }
  label {
    color: colors.$color-black-30;
    cursor: not-allowed;
  }
  label:before {
    border-color: colors.$color-black-30;
    cursor: not-allowed;
  }
}

/** @todo error styling */
.has-error {
  color: colors.$color-error;
}
