@use 'src/theme/styles/variables';

@mixin on() {
  background: #ffffff;
  border: 1px solid rgba(131, 131, 131, 0.1);
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.card {
  position: relative;
  background: #ffffff;
  border: 1px solid rgba(131, 131, 131, 0.1);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: variables.$spacing-xxl;
  transition: all variables.$transition-duration variables.$transition-timing-function;
}

.interactive {
  cursor: pointer;

  &:hover,
  &:focus {
    @include on();
  }
}

.on {
  @include on();
}
