.root {
  position: relative;
}

.sub-text {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.edit-button {
  cursor: pointer;
}

.inline-input {
  border: 0px;
  border-bottom: 1px solid #cccccc;
  width: 60px;
  outline-width: 0;
}
