@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  border-radius: 90%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: colors.$color-black-accent;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: variables.$font-weight-bold;
  font-size: variables.$font-size-body-2;

  &.size-sm {
    width: 32px;
    min-width: 32px;
    height: 32px;
    font-size: variables.$font-size-caption;
  }

  &.contrast-light {
    background: colors.$color-carbon-tint;
  }
  &.contrast-normal {
    background: colors.$color-black-accent;
  }
  &.contrast-dark {
    background: colors.$color-carbon-secondary;
  }

  &.border-colour-none {
    border: 0px;
  }
  &.border-colour-red {
    border: 1px solid colors.$color-red-primary;
  }
  &.border-colour-green {
    border: 1px solid colors.$color-green-primary;
  }
  &.border-colour-blue {
    border: 1px solid colors.$color-blue-primary;
  }
}
