@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;
  [class*='EditReference_amount-text'] {
    span {
      opacity: 0.8;
      font-size: variables.$font-size-caption;
    }
  }
}

:global {
  .Popover {
    z-index: variables.$z-index-5;
  }

  .Popover-body {
    border-radius: 1px;
    font-weight: 300;
  }

  .Popover-simple .Popover-body {
    padding: variables.$spacing-sm;
  }

  .Popover-contrast-light {
    .Popover-body {
      background: colors.$color-white;
      color: colors.$color-black-90;
      border: 1px solid rgba(131, 131, 131, 0.1);
      box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }

    .Popover-tip {
      fill: white;
    }
  }

  .Popover-contrast-dark {
    .Popover-body {
      background: colors.$color-black-90;
      color: colors.$color-white;
    }
  }

  // Sizes
  .Popover-size-sm .Popover-body {
    font-size: variables.$font-size-fine;
  }
  .Popover-size-md .Popover-body {
    font-size: variables.$font-size-body-2;
  }
  .Popover-size-lg .Popover-body {
    font-size: variables.$font-size-body-1;
  }

  // Padding
  .Popover-padding-sm .Popover-body {
    padding: variables.$spacing-sm;
  }
  .Popover-padding-md .Popover-body {
    padding: variables.$spacing-md;
  }
  .Popover-padding-lg .Popover-body {
    padding: variables.$spacing-lg;
  }
}
