@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  display: flex;
  flex-direction: row;
  color: colors.$color-green;
  min-width: 148px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}

.check-icon {
  background: url('../../../../../../theme/icons/valid.svg') no-repeat center left;
  outline: none;
  display: block;
  height: 48px;
  width: 36px;
  background-size: 36px 36px;
}

.header {
  align-self: flex-end;
  font-size: variables.$font-size-body-1;
  font-weight: variables.$font-weight-bold;
}

.body {
  align-self: flex-end;
  font-size: variables.$font-size-caption;
}
