@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    margin-right: variables.$spacing-sm;
    background-color: colors.$color-green;
    border-radius: 90%;
  }
}
