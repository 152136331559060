@use 'src/theme/styles/variables';

.root {
  margin-top: 20px;

  .party-details {
    display: flex;
    flex-direction: column;
  }

  .subtext {
    font-size: variables.$font-size-caption;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 200px;
  }

  .no-content {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }

  // Buttons

  .primary,
  .primary-fill,
  .edit,
  .remove {
    text-align: left;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }

  .primary {
    background-image: url('../../../../theme/icons-v2/Controls/Flag.svg');
  }

  .primary-fill {
    background-image: url('../../../../theme/icons-v2/Controls/Flag-fill.svg');
  }

  .edit {
    background-image: url('../../../../theme/icons-v2/Controls/Edit.svg');
  }

  .remove {
    background-image: url('../../../../theme/icons-v2/Controls/Clear.svg');
  }
}
