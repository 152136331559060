@use 'src/theme/styles/variables';
@use '../../mixins';

.root {
  position: relative;

  .header {
    @include mixins.header;
    display: flex;
    justify-content: space-between;
  }
}
