@use 'src/theme/styles/colors';

.active-invoice-filters {
  li[class*='Filters_active'] {
    &:first-child button {
      border-bottom-color: colors.$color-dark-blue-secondary;
      > span {
        background: colors.$color-dark-blue;
      }
    }
    &:nth-child(2) button {
      border-bottom-color: colors.$color-red-secondary;
      > span {
        background: colors.$color-red;
      }
    }
    &:nth-child(3) button {
      border-bottom-color: colors.$color-orange-secondary;
      > span {
        background: colors.$color-orange;
      }
    }
    &:nth-child(4) button {
      border-bottom-color: colors.$color-yellow-secondary;
      > span {
        background: colors.$color-yellow;
      }
    }
    &:last-child button {
      border-bottom-color: colors.$color-green-secondary;
      > span {
        background: colors.$color-green;
      }
    }
  }
}
