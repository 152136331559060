@use 'src/theme/styles/variables';

.align-left {
  float: left;
}

.align-right {
  float: right;
}

.logo,
.tick,
.loading,
.loading-circle,
.arrow-left,
.clear,
.close,
.edit,
.info,
.trash,
.add,
.calendar,
.options,
.otp-shield,
.otp-shield-and-ring,
.onboarding-verify,
.onboarding-business,
.onboarding-theme,
.onboarding-invite,
.onboarding-property,
.image-icon,
.water-drop,
.search-icon,
.no-content-graphic {
  margin: 0;
}
