@use 'mixins/grid';

// Optional flex classes

// Flow
.rowReverse {
  flex-direction: row-reverse;
}

.colReverse {
  flex-direction: column-reverse;
}

// Gutters
@include grid.setColumnPadding;

// Positions
.center {
  justify-content: center;
  text-align: center;
}

.start {
  justify-content: flex-start;
  text-align: start;
}

.end {
  justify-content: flex-end;
  text-align: end;
}

.top {
  align-items: flex-start;
  display: flex;
  > div {
    width: 100%;
  }
}

.middle {
  align-items: center;
  display: flex;
  > div {
    width: 100%;
  }
}

.bottom {
  align-items: flex-end;
  display: flex;
  > div {
    width: 100%;
  }
}

.around {
  justify-content: space-around;
}

.between {
  justify-content: space-between;
}

.first {
  order: -1;
}

.last {
  order: 1;
}
