@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/functions';

@mixin highlight() {
  /* Shadow: */
  opacity: 0.2;
  background: #000000;
  border-radius: variables.$radius-sm;
  /* Bounds: */
  opacity: 0.8;
  background: colors.$color-carbon-tint;
  border-radius: 0 12px 12px 12px;
  /* Input: */
  opacity: 0.9;
  line-height: 20px;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  &--item {
    padding: variables.$spacing-md;
    opacity: 0.9;
    font-size: 14px;
    color: colors.$color-black;
    line-height: 20px;
    transition: all variables.$transition-duration variables.$transition-timing-function;
    word-break: break-word;

    &__active {
      opacity: 1;
      font-weight: bold;
    }

    &__highlighted {
      @include highlight();
    }

    .list-item-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
  }

  &--item-text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 0.8rem;
    }
  }

  &--item-secondary-text {
    display: block;
    opacity: 0.6;
    font-size: 12px;
  }

  &--item-tertiary-text {
    display: block;
    opacity: 0.6;
    font-size: 12px;
  }

  &--divider {
    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      margin: variables.$spacing-md 0;
      background: colors.$color-black-10;
    }
  }

  // List types
  &--checklist {
    .list--item {
      font-weight: variables.$font-weight-medium;
      padding-left: 0;

      &:before {
        content: '';
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 100px;
        width: 20px;
        display: block;
        height: 20px;
        float: left;
        margin-right: variables.$spacing-md;
      }

      &__active {
        font-weight: variables.$font-weight-medium;
        opacity: 0.9;
        &:before {
          background: rgba(0, 0, 0, 0.9);
          background-image: url('../../../../theme/icons-v2/Controls/Check.svg');
          background-repeat: no-repeat;
          background-size: 100%;
          filter: brightness(100);
        }
      }
    }

    [class*='--item-secondary-text'] {
      margin-left: 36px;
    }

    [class*='--item-tertiary-text'] {
      margin-left: 36px;
    }
  }

  // &--navigation {
  //   .list--item {
  //     font-size: convert-to-rem(16);
  //     border-top: 1px solid colors.$color-black-10;
  //   }
  // }

  // States
  &--interactive {
    .list--item {
      cursor: pointer;

      &:hover {
        @include highlight();
      }
    }
  }

  &.list--navigation {
    .list--item {
      background: transparent;
      color: colors.$color-black-50;
      font-size: functions.convert-to-rem(16);
      font-weight: variables.$font-weight-medium;
      border-bottom: 1px solid colors.$color-black-10;
      margin-bottom: variables.$spacing-sm;

      &:focus {
        box-shadow: none;
        background-color: color.adjust(colors.$color-black-10, $alpha: -0.06);
        // color: colors.$color-black;
      }

      &__active {
        opacity: 1;
        color: colors.$color-black-20;

        &:focus {
          background-color: transparent;
        }
      }
    }
  }
}
