@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  header {
    display: flex;
    align-items: flex-end;
    h3 {
      margin: 0;
    }
  }
}

.link {
  @include button.button-reset;
  color: colors.$color-link;
  cursor: pointer;
  transition: all variables.$transition-duration variables.$transition-timing-function;

  &:hover,
  &:focus {
    color: colors.$color-link-hover;
  }
}
