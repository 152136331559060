@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

$checkbox-width: 40px;

@mixin checkbox() {
  &:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $checkbox-width;
    padding: 0;

    label:before {
      padding: 0;
    }
  }
}

.root {
  border-collapse: collapse;
  width: 100%;
  border-radius: variables.$radius-sm;
  display: flex;
  flex-direction: column;
  height: 100%;

  .thead {
    .row {
      display: flex;
      .th {
        flex: 1;
        box-shadow: none;
        font-size: 1rem;
        text-transform: uppercase;
        border: none;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
      }
    }
  }

  .tbody {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div > div {
      @include general.styled-scrollbar;
    }

    .row {
      border-top: 1px solid colors.$color-black-10;
      display: flex;
      background: colors.$color-grey-tint;

      // &:hover {
      //   background-color: transparentize($color: colors.$color-system-primary, $amount: 0.95);
      // }

      &.interactive {
        cursor: pointer;
        &:hover {
          transition: all variables.$transition-duration variables.$transition-timing-function;
          background-color: colors.$color-carbon-tint;
        }
      }

      &.error {
        background-color: colors.$color-error-accent;

        &:hover {
          background-color: colors.$color-error-secondary;
        }
      }

      &.animate {
        &-danger,
        &-danger-flash,
        &-success {
          transition: all variables.$transition-duration ease-in;
        }
      }

      &.animate-danger {
        background-color: colors.$color-red-accent !important;
        transform: translateX(-100%);
      }

      &.animate-danger-flash {
        background-color: colors.$color-red-accent !important;
      }

      &.animate-success {
        background-color: colors.$color-system-accent !important;
        transform: translateX(100%);
      }

      &.selected,
      &.highlighted {
        background-color: colors.$color-carbon-tint;
      }

      .td {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
      }
    }
  }

  .th {
    font-size: variables.$font-size-fine;
    color: colors.$color-black-30;
    font-weight: variables.$font-weight-medium;
  }

  .th,
  .td {
    padding: variables.$spacing-md variables.$spacing-sm;
    text-align: left;
  }

  // Types
  &.is-simple {
    .th,
    .td {
      padding: variables.$spacing-sm;
    }

    .tbody {
      .row {
        background: transparent;
        border: none;
      }
    }
  }

  // Checkbox
  &.is-selectable {
    .tbody,
    .thead {
      .row {
        .th,
        .td {
          @include checkbox;
        }
      }
    }
  }
}
