@use 'node_modules/@carbon/layout/scss/breakpoint';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/mixins/general';

@mixin drawer() {
  height: 99vh;
  border-radius: variables.$drawer-radius;
  position: fixed;
  top: 0.5vh;
  right: 0.5vh;
  z-index: variables.$z-index-4;
  width: 100%;
  padding: 96px variables.$spacing-md;
  color: colors.$color-white;
  overflow-x: visible;
  overflow-y: scroll;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.2);
  @include general.styled-scrollbar;

  @include breakpoint.carbon--breakpoint-up('md') {
    width: 375px;
  }

  h1 {
    margin: 0 variables.$spacing-sm variables.$spacing-md;
    font-size: 32px;
  }

  .close {
    @include button.button-reset;
    position: absolute;
    top: variables.$spacing-sm;
    right: variables.$spacing-sm;

    svg path {
      fill: colors.$color-white;
      opacity: 1;
    }
  }
}
