@use 'node_modules/@carbon/layout/scss/breakpoint';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/mixins/general';
@use '../drawer';

.root {
  @include drawer.drawer;
  background: #f0eef0;
  border: 1px solid #e2e2e2;
  color: colors.$color-text;
  padding: variables.$spacing-md;
  @include general.styled-scrollbar;

  .commission-account {
    margin-top: variables.$spacing-md;
  }

  &:after {
    position: sticky;
    bottom: -(variables.$spacing-md);
    content: '';
    background-image: linear-gradient(180deg, rgba(240, 238, 240, 0) 0%, #f0eef0 100%);
    width: 100%;
    height: 80px;
    display: block;
  }

  .help {
    display: flex;
    justify-content: flex-end;

    button,
    a {
      @include button.button-reset;
      outline: none;
      display: flex;
      align-items: center;
      background: colors.$color-white;
      border-radius: 20px;
      padding: 4px variables.$spacing-sm;
      color: colors.$color-black-60;
      text-decoration: none;
      padding: variables.$spacing-sm;
      margin-left: variables.$spacing-sm;

      > span {
        background: red;
        color: white;
        float: right;
        padding: 4px;
        margin-left: variables.$spacing-sm;
      }
    }
  }
}
