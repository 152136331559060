@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.label {
  background-color: colors.$color-black-05;
  color: colors.$color-black;
  border-radius: variables.$radius-sm;
  font-size: variables.$font-size;
  min-width: 28px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &.active {
    background-color: colors.$color-black-secondary;
    color: colors.$color-white;
  }

  &.basic {
    background-color: transparent;
    border: 1px solid colors.$color-black-10;
  }

  &.circular {
    border-radius: 12px;
  }

  &.horizontal {
    margin: 0 variables.$spacing-md;
  }

  &.size {
    &-sm {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    &-md {
      position: relative;
      padding: 4px variables.$spacing-sm;
    }
    &-lg {
      font-size: variables.$font-size-body-1;
      padding: variables.$spacing-sm variables.$spacing-md;
    }
  }

  &.empty {
    @include general.ir();
    min-width: 0;
    min-height: 0;
    overflow: hidden;
    width: 0.3em;
    height: 0.3em;
    vertical-align: baseline;
    padding: 4px;
  }
}
