@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  width: 100%;

  :global {
    .react-select__menu {
      padding: 0;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    }
  }

  .value-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 24px;

    span {
      line-height: 24px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .option {
    display: flex !important;
    align-items: center;
    padding: variables.$spacing-md !important;
    cursor: pointer !important;
    justify-content: space-between !important;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
    }

    &:hover,
    &--is-focused {
      background-color: colors.$color-grey-tint !important;
    }

    &--is-selected {
      background-color: colors.$color-carbon-tint !important;
      color: colors.$color-black-primary !important;
    }
  }
}
