@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  position: relative;
  width: 100%;

  :global {
    .recharts-legend-item {
      margin-right: 5px !important;
    }

    .recharts-legend-item-text {
      font-size: variables.$font-size-fine;
      color: colors.$color-carbon-primary !important;
    }
    .recharts-cartesian-axis-tick-value {
      text-transform: uppercase;
      font-size: variables.$font-size-fine;
      stroke: colors.$color-grey;
      font-weight: variables.$font-weight-thin;
      letter-spacing: 1px;
    }

    .recharts-tooltip-wrapper {
      background-color: colors.$color-carbon-primary !important;
    }
  }
}

.line {
  stroke-width: 10px;
}

.tooltip {
  margin-right: 20px;
  font-size: variables.$font-size-caption;

  ul {
    list-style: none;
    margin: 0;
    padding: variables.$spacing-md;

    li {
      display: flex;
      align-items: center;

      &:before {
        content: '•';
        font-size: 30px;
        line-height: 1;
        margin-right: variables.$spacing-sm;
      }

      // @todo revisit da hacks
      &.active-properties-list-item {
        line-height: 2;
        &:before {
          content: '◽';
          font-size: 12px;
          margin-top: 4px;
        }
      }

      div {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      span {
        color: colors.$color-white-secondary;
        padding-top: 4px;
      }
    }
  }
}
