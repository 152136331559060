@use 'src/theme/styles/variables';

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
  width: 100%;
}

.container {
  min-height: 620px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  align-content: stretch;
}

.messages {
  margin: 0;
  display: flex;
  flex-direction: column;

  .message {
    margin-bottom: variables.$spacing-sm;
  }
}

.body {
  margin: variables.$spacing-xxxl 0;
  display: flex;
  flex-grow: 1;

  > * {
    width: 100%;
  }
}

.actions {
  display: flex;
  position: absolute;
  bottom: 0px;
  right: 0;

  .action {
    margin-left: variables.$spacing-sm;
  }
}
