@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  margin-bottom: variables.$spacing-md;
  box-shadow: 0 2px 7px colors.$color-black-tint;

  h3 {
    margin: 13px 0;
  }

  > div {
    border-color: colors.$color-carbon-accent !important;
  }
}
