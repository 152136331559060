@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  :global {
    .Modal_Actions button:first-child {
      border-right: 1px solid colors.$color-black-10;
      background: colors.$color-green-primary;
    }
  }
}
