@use '../../../../../views/components/atoms/TextField/TextField.module.scss';

.date-field,
.phone-field {
  @include TextField.text-input-base();
}

.footer {
  margin-top: 2rem;
}
