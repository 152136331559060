@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  border-radius: variables.$radius-sm;
  display: block;
  width: 40px;
  height: 24px;
  line-height: 20px;
  text-align: center;
  font-size: variables.$font-size;
  font-style: normal;
  font-weight: variables.$font-weight-medium;
  letter-spacing: 1px;
  border: 2px solid colors.$color-grey;
  color: colors.$color-grey;
  cursor: pointer;
}

.is-enabled {
  background: colors.$color-black-90;
  color: colors.$color-white;
  border-color: colors.$color-black-90;
}
