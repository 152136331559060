@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;
  z-index: 100;
}

.errors {
  color: colors.$color-red;
}

.vat {
  display: flex;
  justify-content: flex-end;
}
