@use 'src/theme/styles/colors';

.root {
  [class*='Modal_Title'] {
    margin: 0 -24px 0 -24px;
    padding: 0 24px 14px;
    border-bottom: 1px solid colors.$color-black-10;
  }

  [class*='Modal_Content'] {
    margin: 24px 0 8px;
    p {
      margin: 0 0 8px;
    }
    [class*='Switch_root'] {
      display: flex;
      font-size: 1.2rem;
    }
  }
}
