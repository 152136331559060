@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/functions';
@use 'src/theme/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  // @todo make mixin for this
  a {
    color: colors.$color-link;
    text-decoration: none;

    &:hover,
    &:focus {
      color: colors.$color-link-hover;
    }
  }

  :global {
    .rt-tr-group {
      background: url('../../../../../../theme/icons/right-arrow-off.svg') no-repeat right center;
    }
  }
}
