@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/forms';

.root {
  position: relative;
}

.form {
  @include forms.fullHeightForm();
}
