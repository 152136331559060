@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.date-range {
  margin: variables.$spacing-lg 0;
  padding-bottom: variables.$spacing-lg;
  border-bottom: 2px solid colors.$color-carbon-tint;
}

.download-buttons {
  > button {
    margin-bottom: variables.$spacing-md;
  }
}

.pdf,
.csv {
  text-align: left;
  background-repeat: no-repeat;
  background-position: 96% center;
}

.pdf {
  background-image: url('../../../../../theme/icons-v2/Doc/PDF-alt.svg');
}

.csv {
  background-image: url('../../../../../theme/icons-v2/Doc/CSV.svg');
}
