@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

$border-color: colors.$color-carbon-tint;
$padding: variables.$spacing-md;

.root {
  border: 1px solid $border-color;
  margin-bottom: variables.$spacing-lg;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    padding: $padding;
  }
}

.amounts {
  display: flex;
  flex-direction: column;

  .title {
    font-size: variables.$font-size-fine;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: variables.$font-weight-medium;
    margin: 0;
  }

  p {
    margin-bottom: 0;
    strong {
      font-size: variables.$font-size-a3;
      display: block;
      color: colors.$color-text;
      font-weight: variables.$font-weight-medium;
    }
    span {
      font-size: variables.$font-size-caption;
    }
  }
}

.actions {
  display: flex;
}

.statement-summary {
  padding: $padding;
  border-bottom: 1px solid $border-color;
}
