.line {
  position: relative;
}

.line-trail {
  position: relative;
}

.line-path {
  position: relative;
}
