@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  position: absolute;
  border-right: 1px solid colors.$color-carbon-tint;
  width: 100vw;
  height: 100%;
  overflow-y: scroll;

  @include general.hide-scrollbar;

  @media #{variables.$sm} {
    width: 240px;
  }
}
