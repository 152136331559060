@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  position: relative;
}

.header {
  display: flex;
  border-bottom: 1px solid colors.$color-black-10;

  &-info {
    flex-grow: 1;
  }

  &-controls {
    position: relative;
  }
}

.invoice-number {
  font-size: variables.$font-size;
  text-transform: uppercase;
}

.due-date {
  margin-left: auto;
  width: 40%;
}

.save-button {
  margin-right: variables.$spacing-md;
}

.invoice-data {
  position: relative;
  flex-grow: 1;
  display: flex;
  margin-top: variables.$spacing-md;

  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    // @todo create mixin for pulling children out of container
    .invoice-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: -24px;
      margin-right: -24px;
      padding: variables.$spacing-lg;
      border-bottom: 1px solid colors.$color-black-10;

      > div[class*='FormField-module'] {
        flex: 1;
      }

      h3 {
        font-size: variables.$font-size-a3;
      }

      a {
        margin-right: -#{variables.$spacing-sm};
      }
    }

    > div[class*='Grid-module'] {
      flex: none;
    }

    > div[class*='Grid-module']:last-of-type {
      flex: 1;
    }

    footer {
      background: colors.$color-white;

      border-top: 1px solid colors.$color-black-10;
      // @todo re-enable once intersectionObserver is figured out
      // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
      margin: 0 - (variables.$spacing-lg);
      padding: variables.$spacing-xl variables.$spacing-lg;

      .form-footer {
        display: flex;
        margin-bottom: variables.$spacing-md;
      }
    }
  }
}
