@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  position: relative;
  display: block;
  width: 100%;
  margin: variables.$spacing-xxl 0;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: colors.$color-black-10;
  }
}
