@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use '../../mixins';

.root {
  .header {
    @include mixins.header;
  }
}

.edit {
  @include button.button-reset;
  outline: none;
}
