@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.amount-text {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.error-text {
  color: colors.$color-error;
}

.partial-text {
  color: colors.$color-orange;
}

.paid-text {
  color: colors.$color-success;
}

.edit-button {
  cursor: pointer;
  @include button.button-reset;
}

.inline-input {
  border: 0px;
  border-bottom: 1px solid #cccccc;
  width: 60px;
  outline-width: 0;
}
