@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  @include general.hide-scrollbar;
}
