@use 'src/theme/styles/variables';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  :global {
    .rt-tr-group {
      background: url('../../../../../theme/icons/right-arrow-off.svg') no-repeat right center;
    }
  }
}
