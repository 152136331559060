@use 'src/theme/styles/colors';
@use 'src/theme/styles/mixins/button';

.root {
  height: 100vh;

  button {
    @include button.button-reset();
    color: colors.$color-link;
    &:hover,
    &:focus {
      color: colors.$color-link-hover;
    }
  }
}
