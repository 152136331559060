@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  display: flex;
  flex: 1;
  background-color: white;
}

.header {
  padding: variables.$spacing-xxl variables.$spacing-xxl 0;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.footer {
  margin-top: variables.$spacing-xl;
  text-align: center;

  button {
    @include button.button-reset;
    outline: none;
    padding: 0 variables.$spacing-sm;
    text-decoration: underline;
  }
}
