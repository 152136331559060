@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root [class*='rbn--col-lg-3'] {
  max-width: 21.05%;
  flex: 0 0 21.05%;
}

h3 {
  margin-top: 1rem;
}

p {
  font-size: 1.4rem;
}

.pill {
  background: colors.$color-teal;
  color: colors.$color-white;
  padding: 4px variables.$spacing-sm;
  border-radius: 90px;
  display: inline-flex;
  align-items: center;
  margin: 0 0.5rem 0.5rem 0;
}

.report-background {
  background-color: colors.$color-grey-tint;
  padding: 1.2rem 2rem;
  margin: 2rem 0 3rem;
  border-top: 1px solid colors.$color-grey-accent;
}

.button {
  margin-right: 2rem;
}
