@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.timestamp {
  small {
    margin-bottom: 0.2rem;
  }
}

.resend {
  &:hover {
    background-image: url('../../../../theme/icons-v2/Controls/Send-fill.svg');
  }

  background-image: url('../../../../theme/icons-v2/Controls/Send.svg');
  text-align: left;
  background-repeat: no-repeat;
  background-position: left;
  border: none;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.datepicker-wrapper {
  display: flex;
  padding: variables.$spacing-xxl 0 variables.$spacing-md;
  max-width: 350px;
}

.status-label {
  margin: 0;
  color: #fff;
  opacity: 0.8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 90px;
  text-transform: uppercase;
  font-size: variables.$font-size;
  font-weight: variables.$font-weight-regular;
  line-height: 1.15;
}
