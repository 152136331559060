@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  display: flex;
  align-items: center;
  padding: variables.$spacing-xxl 0 variables.$spacing-md;

  i {
    margin: 0;
    margin-right: variables.$spacing-md;
  }

  .heading {
    font-size: variables.$font-size-a2;
    font-weight: variables.$font-weight-medium;
    margin: variables.$spacing-sm 0;
    color: colors.$color-black-primary;
  }
}
