@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

// Input
$input-font-size: variables.$text-field-font-size;
$input-border-color: colors.$color-grey-secondary;
$input-background-color: colors.$color-white;
$input-border-radius: variables.$radius-sm;
$input-placeholder-color: colors.$color-carbon-secondary;
$input-height: 48px;

// Disabled
$input-disabled-background-color: rgba(27, 32, 33, 0.04);
$input-disabled-placeholder-color: colors.$color-black-30;

// Focused
$input-focus-border-color: colors.$color-dark-blue;
$label-font-size: variables.$font-size-caption;

// Label
$label-color: colors.$color-black-secondary;
$input-focused-label-color: colors.$color-black;

.root {
  width: 100%;
  margin-top: 20px;

  .header {
    padding: 0;
    h4 {
      font-size: variables.$font-size-body-1;
    }
    display: flex;
    justify-content: space-between;
  }

  .file-entry-input {
    width: 100%;
    background: $input-background-color;
    border: 2px solid $input-border-color;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0px;
    padding-left: 0px !important;
    font-size: $input-font-size;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 variables.$spacing-md;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:focus {
      outline: none;
      border-color: $input-focus-border-color;
    }

    &::placeholder {
      color: $input-placeholder-color;
      font-size: variables.$font-size-body-2;
      font-style: italic;
    }

    &[disabled] {
      background: $input-disabled-background-color;
      cursor: not-allowed;

      &::placeholder {
        color: $input-disabled-placeholder-color;
      }
    }
  }

  .file-details {
    //display: flex;
    flex-direction: column;
  }

  .filename {
    display: inline-block !important;
  }

  .subtext {
    display: block !important;
    font-size: variables.$font-size-caption;
  }

  .time-custom-details {
    flex-direction: column;
  }

  .timeago-custom-style {
    display: inline-block !important;
    font-size: small;
  }

  .timeago-custom-style-below {
    display: inline-block !important;
    font-size: smaller;
  }

  .timestring {
    display: block !important;
    /*font-size: $font-size-caption;*/
    font-size: smaller;
  }

  .timestring-below {
    display: block !important;
    /*font-size: $font-size-caption;*/
    font-size: smaller;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 200px;
  }

  .no-content {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }

  // Buttons

  .save,
  .edit,
  .download,
  .upload,
  .remove {
    text-align: left;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }

  .save {
    background-image: url('../../theme/icons-v2/Controls/Save.svg');
  }

  .edit {
    background-image: url('../../theme/icons-v2/Controls/Edit.svg');
  }

  .remove {
    background-image: url('../../theme/icons-v2/Controls/Clear.svg');
  }

  .upload {
    background-image: url('../../theme/icons-v2/Controls/Upload.svg');
  }

  .download {
    background-image: url('../../theme/icons-v2/Controls/Download.svg');
  }
}
