@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/forms';

.root {
  h2 {
    margin-bottom: variables.$spacing-md;
    font-size: variables.$font-size-a2;
  }

  form {
    @include forms.form();

    button {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: variables.$spacing-xl;
  }

  a {
    color: colors.$color-system-secondary;
  }
}

.error {
  color: red;
}

.submit-button {
  margin-top: variables.$spacing-md;
  > span {
    // display: block;
  }
}
