@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.loading-modules {
  padding: 2rem 0;
}

.root {
  overflow-y: scroll;
  max-height: 600px;
  margin-bottom: 2.8rem;
}

.module {
  padding: 0.5rem 1rem 0 0;
  margin-bottom: 2rem;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  h3 {
    margin: 0;
  }

  .meta {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      display: flex;
      align-items: center;
      font-style: italic;
      font-size: 1rem;
      font-weight: 500;
      svg {
        background-color: colors.$color-teal;
        border-radius: 100px;
        width: 20px;
        height: 20px;
        margin-top: 0.4rem;
        margin-right: 1rem;
        path {
          fill: colors.$color-white;
        }
      }
    }

    .price {
      font-size: 1.4rem;
      font-weight: 700;
    }
  }
}

p {
  margin: 0.5rem 0 1rem;
}

small {
  display: flex;
  margin: 0 0 1rem;
}
