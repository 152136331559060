@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  display: flex;
  flex: 1;
  background-color: colors.$color-white-primary;
  width: 100%;
}

.header {
  padding: variables.$spacing-xxl variables.$spacing-xxl 0;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
