@use 'sass:meta';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

$font-size: variables.$font-size-body-2;
$transition: all variables.$transition-duration variables.$transition-timing-function;

$tab-padding: variables.$spacing-sm variables.$spacing-md;
$tab-color: colors.$color-black-primary;
$tab-radius: variables.$radius-sm;
$tab-text-align: center;

// Selected
$tab-selected-background: colors.$color-black-primary;
$tab-selected-color: colors.$color-white;

:global {
  @include meta.load-css('node_modules/react-tabs/style/react-tabs.scss');
}

.root {
  position: relative;

  :global {
    .react-tabs {
      &__tab-list {
        border: none;
        margin-bottom: variables.$spacing-lg;
      }

      &__tab {
        background: colors.$color-white;
        border: 1px solid colors.$color-black-primary;
        padding: $tab-padding;
        font-size: variables.$font-size;
        color: $tab-color;
        letter-spacing: 0;
        text-align: $tab-text-align;
        transition: $transition;
        border-radius: 0;

        &:not(:first-child) {
          margin-left: -1px;
        }

        &--selected {
          background: $tab-selected-background;
          color: $tab-selected-color;
          font-weight: variables.$font-weight-medium;
        }

        &:first-child {
          border-top-left-radius: $tab-radius;
          border-bottom-left-radius: $tab-radius;
        }

        &:last-child {
          border-top-right-radius: $tab-radius;
          border-bottom-right-radius: $tab-radius;
        }
      }

      &__tab-panel {
        display: block;
        visibility: hidden;
        opacity: 0;
        transition: $transition;

        &--selected {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
