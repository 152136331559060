@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  border: 1px solid transparent;
  padding: variables.$spacing-sm;
  border-radius: variables.$radius-sm;

  &.type--success {
    background: colors.$color-success-tint;
    border-color: colors.$color-success-accent;
  }

  &.type--info {
    background: colors.$color-info-tint;
    border-color: colors.$color-info-accent;
  }

  &.type--warning {
    background: colors.$color-warning-accent;
    border-color: colors.$color-warning-secondary;
  }

  &.type--error {
    background-color: colors.$color-error-tint;
    border-color: colors.$color-error;
  }
}
