@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/functions';

$segment-border-color: colors.$color-carbon-tint;

.segments {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: variables.$spacing-md 0;
  border: 1px solid $segment-border-color;
  border-radius: variables.$radius-sm;

  &.state {
    &-danger {
      border: 1px solid red !important;
    }
    &-warning {
      border: 1px solid orange !important;
    }
    &-info {
      border: 1px solid blue !important;
    }
    &-default {
      border-color: $segment-border-color;
    }
  }

  // Nested segment groups
  > .segments {
    border-left: none;
    border-right: none;
    margin-top: -1px;
    margin-bottom: 0;
    border-radius: 0;
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  // Segment
  .segment {
    padding: variables.$spacing-md;
    display: flex;
    flex-direction: column;

    &.horizontal {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &.state {
      &-danger {
        border: 1px solid red !important;
      }
      &-warning {
        border: 1px solid orange !important;
      }
      &-info {
        border: 1px solid blue !important;
      }
      &-default {
        border-color: $segment-border-color;
      }
    }

    &.interactive {
      cursor: pointer;
    }
  }

  &.horizontal {
    flex-direction: row;

    > .segment {
      flex: 1 1 auto;
      &:not(:last-child) {
        border-right: 1px solid colors.$color-black-10;
      }
    }
  }

  &:not(.horizontal) > {
    .segment {
      &:not(:last-child) {
        border-bottom: 1px solid $segment-border-color;
      }
      &:first-child {
        border-top: none;
        margin-top: 0;
        bottom: 0;
        margin-bottom: 0;
        top: 0;
        border-radius: variables.$radius-sm variables.$radius-sm 0 0;
      }
    }
  }

  &.interactive {
    cursor: pointer;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:hover {
      border-color: colors.$color-black-30;
    }
  }
}

// ------
// Segment Label
// ------
.segment-label {
  display: flex;
  align-items: center;

  .icon {
    border-right: 1px solid $segment-border-color;
    padding-right: variables.$spacing-md;
    margin-right: variables.$spacing-md;
  }
  .text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: colors.$color-text;
  }
  strong {
    font-size: functions.convert-to-rem(16);
    font-weight: variables.$font-weight-medium;
  }
}

// Sizes
.size-sm {
  font-size: variables.$font-size-fine;
}
.size-md {
  position: relative;
}
.size-lg {
  font-size: variables.$font-size-body-1;
}
