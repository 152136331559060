@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/animations';
@use 'src/theme/styles/mixins/forms';

$circle-size: 40px;

.text {
  transition: all 2.5s ease;
  opacity: 1;
  width: auto;
}

.modal-header {
  margin: 0.5rem 0 1.8rem;
  font-size: 2.2rem;
}

.modal-content {
  [class*='rbn--col-lg-6'] {
    max-width: 48.5%;
    flex: 0 0 48.5%;
  }
  [class*='rbn--col-lg-4'] {
    max-width: 32.3%;
    flex: 0 0 32.3%;
  }
  [class*='rbn--col-lg-3'] {
    max-width: 24.25%;
    flex: 0 0 24.25%;
  }
}

.footer {
  > button,
  > a {
    margin-right: variables.$spacing-sm;
  }
}
