@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/functions';
@use 'src/theme/styles/mixins/button';

$color: colors.$color-black-50;
$color-hover: colors.$color-black-60;

.root {
  @include button.button-reset;
  outline: none;
  display: inline-block;
  font-size: functions.convert-to-rem(16);
  font-weight: variables.$font-weight-medium;
  color: $color;
  text-decoration: none;

  &:before {
    content: '';
    display: block;
    float: left;
    background: colors.$color-grey-tint url('../../../../theme/icons/back.svg') no-repeat center center;
    border-radius: 50%;
    margin-right: 5px;
    width: variables.$spacing-xxl;
    height: variables.$spacing-xxl;
    transition: background-color variables.$transition-duration variables.$transition-timing-function;
  }

  &:hover,
  &:focus {
    &:before {
      background-color: colors.$color-grey-accent;
    }
  }
}
