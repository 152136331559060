@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  position: relative;
  width: 100%;

  > div[class*='Segment-segments'] {
    border: none;
  }
}

.amount {
  margin-left: auto; // pull control to the end of container
}

.error {
  display: flex;
  flex-direction: column;
  color: colors.$color-error;
}

.status-ready {
  position: relative;
}

.beneficiaries {
  @include general.reset-list;
  margin-bottom: variables.$spacing-md;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  padding: 5px 6px;
}

.error-item-wrapper {
  border: 1px solid colors.$color-error;
  padding: 5px 5px;
}
