@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  @include button.button-reset;
  display: inline-block;
  background-color: colors.$color-system; // @todo replace with theme primary
  border-radius: 12px 12px 0px 12px;
  opacity: 0.8;
  color: colors.$color-white;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  transition: all variables.$transition-duration variables.$transition-timing-function;

  &:focus,
  &:hover {
    background-color: color.adjust($color: colors.$color-system, $alpha: -0.1);
  }

  &:active {
    background-color: color.adjust($color: colors.$color-system, $lightness: -10%);
  }
}

.disabled {
  &,
  &:hover,
  &:focus {
    background-color: color.adjust($color: colors.$color-black, $alpha: -0.95);
    opacity: 0.8;
    border: 1px solid colors.$color-black-10;
    color: colors.$color-black-90;
    cursor: not-allowed;
  }
}
