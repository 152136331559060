@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  display: inline-block;
  background: colors.$color-black-primary;
  padding: variables.$spacing-sm;
  border-radius: 1px 200px 200px 90px;
}

.more {
  @include button.button-reset;
  background: colors.$color-carbon-primary;
  border-radius: 50%;
  float: right;
  display: block;
  width: 40px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background: colors.$color-carbon-secondary;
  }

  svg path:last-child {
    fill: colors.$color-white;
  }
}

.action {
  @include button.button-reset;
  margin-right: variables.$spacing-sm;
  border-radius: variables.$radius-sm;
  background-color: colors.$color-system-primary;
  color: colors.$color-white;
  padding: variables.$spacing-sm;
  font-size: variables.$font-size-body-2;
  line-height: 2.4rem;
  transition: all variables.$transition-duration variables.$transition-timing-function;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: colors.$color-system-secondary;
  }

  &:last-child {
    margin-right: 0;
  }

  span {
    padding-left: variables.$spacing-xl;
    color: colors.$color-white-secondary;
  }

  &.danger {
    background-color: colors.$color-red-primary;

    &:hover,
    &:focus {
      background-color: colors.$color-red-secondary;
    }
  }
}
