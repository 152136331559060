@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  width: 100%;
}

.footer {
  margin-top: variables.$spacing-xxl;

  button {
    margin-right: variables.$spacing-md;
  }
}

.warning {
  header {
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
  }

  button {
    @include button.button-reset;
    color: colors.$color-link;
    cursor: pointer;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:hover,
    &:focus {
      color: colors.$color-link-hover;
    }
  }
}
