@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/mixins/general';

.root {
  border: 1px solid colors.$color-black-10;
  padding: variables.$spacing-md;
  margin-top: variables.$spacing-lg;
  border-radius: variables.$radius-md;
}

.themes {
  display: flex;
  flex-wrap: wrap;
  margin: variables.$spacing-lg 0;

  &:hover button {
    opacity: 0.3;
  }

  button {
    @include general.ir();
    @include button.button-reset();
    outline: none;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 90%;
    margin: 0 variables.$spacing-sm variables.$spacing-md;
    background-color: colors.$color-black-05;
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:hover,
    &:focus {
      opacity: 1;
      margin-top: -5px;
    }

    &.active-theme {
      opacity: 1;

      &:before {
        position: absolute;
        content: '';
        width: 70px;
        height: 70px;
        top: -8px;
        left: -8px;
        display: block;
        border-radius: 90%;
        background: transparent;
        border: 1px solid colors.$color-black-30;
      }
    }
  }
}

.has-error {
  border-color: colors.$color-red;
  background-color: color.adjust(colors.$color-red, $lightness: 45%);
}
