@use 'node_modules/@carbon/layout/scss/breakpoint';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';
@use '../drawer';

.root {
  @include drawer.drawer;
  background: colors.$color-dark-blue-primary;

  .shortcuts {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: variables.$spacing-sm;
    }
  }
}
