@use 'sass:color';
@use 'sass:meta';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

:global {
  @include meta.load-css('slider');
}

@mixin image-wrapper() {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: variables.$spacing-lg;
  background-color: color.adjust(colors.$color: colors.$color-black-10, $alpha: -0.09);
  border: 1px solid colors.$color-black-10;
  border-top-left-radius: variables.$radius-md;
  border-top-right-radius: variables.$radius-md;
  min-height: 480px;
}

@mixin active() {
  border-color: colors.$color-system-primary;
  background-color: color.adjust(colors.$color-system-primary, $lightness: 45%);
}

.root {
  position: relative;
}

.image-container {
  position: relative;
}

.saved-pic {
  @include image-wrapper();

  img {
    border-radius: variables.$spacing-md;
    border: 2px solid transparent;
  }
}

.file-upload {
  @include image-wrapper();

  canvas {
    border-radius: variables.$radius-lg;
    background-color: colors.$color-black-50;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  :global {
    .drop-zone {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px solid colors.$color-black-10;
      border-radius: variables.$radius-lg;
      padding: 100px;
      text-align: center;
      transition: all variables.$transition-duration variables.$transition-timing-function;

      &:hover,
      &focus {
        @include active();
      }
    }
  }
}

.context-info {
  position: relative;
  display: block;
  width: 100%;
  padding: variables.$spacing-md;
  text-align: center;
  background-color: colors.$color-white;
  border: 1px solid colors.$color-black-10;
  margin: -1px 0 0;
  border-radius: 0 0 variables.$radius-sm variables.$radius-sm;

  :global {
    .rc-slider {
      max-width: 240px;
      margin: 0 auto;
    }
    .rc-slider-handle {
      width: 15px;
      height: 15px;
      background: #ffffff;
      border: 2px solid #006eff;
      border-radius: 90%;
    }
    .rc-slider-track {
      background-color: colors.$color-black-05;
    }
  }
}

.edit {
  text-align: right;

  button {
    color: colors.$color-black-80;
    padding: variables.$spacing-sm;
  }
}

.crop-actions {
  position: absolute;
  top: 0;
  right: 0;

  button:first-child {
    color: colors.$color-black-50;
  }
}

.progress-wrapper {
  @include image-wrapper();

  .upload-progress {
    width: 400px;
    height: 400px;
    border-radius: variables.$radius-md;
    border-style: solid;
    border-width: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include active();

    svg {
      width: 70%;
    }
  }
}

.uploading-text {
  text-align: right;
  margin: 0;
  color: colors.$color-black-60;
}

.has-errors {
  .file-upload {
    border-color: colors.$color-red;
    background-color: color.adjust(colors.$color-red, $lightness: 45%);
  }

  .context-info {
    border-color: colors.$color-red;
    background-color: color.adjust(colors.$color-red, $lightness: 45%);
  }
}
