@use 'variables';

// @font-face {
//   font-family: 'Inter';
//   font-style:  normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-Thin-BETA.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-Thin-BETA.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter';
//   font-style:  italic;
//   font-weight: 100;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-ThinItalic-BETA.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-ThinItalic-BETA.woff") format("woff");
// }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('#{variables.$font_path}/inter/Inter-ExtraLight.woff2') format('woff2'),
    url('#{variables.$font_path}/inter/Inter-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('#{variables.$font_path}/inter/Inter-ExtraLightItalic.woff2') format('woff2'),
    url('#{variables.$font_path}/inter/Inter-ExtraLightItalic.woff') format('woff');
}

// @font-face {
//   font-family: 'Inter';
//   font-style:  normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-Light.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-Light.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter';
//   font-style:  italic;
//   font-weight: 300;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-LightItalic.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-LightItalic.woff") format("woff");
// }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{variables.$font_path}/inter/Inter-Regular.woff2') format('woff2'),
    url('#{variables.$font_path}/inter/Inter-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('#{variables.$font_path}/inter/Inter-Italic.woff2') format('woff2'),
    url('#{variables.$font_path}/inter/Inter-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('#{variables.$font_path}/inter/Inter-Medium.woff2') format('woff2'),
    url('#{variables.$font_path}/inter/Inter-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('#{variables.$font_path}/inter/Inter-MediumItalic.woff2') format('woff2'),
    url('#{variables.$font_path}/inter/Inter-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('#{variables.$font_path}/inter/Inter-SemiBold.woff2') format('woff2'),
    url('#{variables.$font_path}/inter/Inter-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('#{variables.$font_path}/inter/Inter-SemiBoldItalic.woff2') format('woff2'),
    url('#{variables.$font_path}/inter/Inter-SemiBoldItalic.woff') format('woff');
}

// @font-face {
//   font-family: 'Inter';
//   font-style:  normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-Bold.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-Bold.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter';
//   font-style:  italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-BoldItalic.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-BoldItalic.woff") format("woff");
// }

// @font-face {
//   font-family: 'Inter';
//   font-style:  normal;
//   font-weight: 800;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-ExtraBold.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-ExtraBold.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter';
//   font-style:  italic;
//   font-weight: 800;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-ExtraBoldItalic.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-ExtraBoldItalic.woff") format("woff");
// }

// @font-face {
//   font-family: 'Inter';
//   font-style:  normal;
//   font-weight: 900;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-Black.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-Black.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter';
//   font-style:  italic;
//   font-weight: 900;
//   font-display: swap;
//   src: url("#{$font_path}/inter/Inter-BlackItalic.woff2") format("woff2"),
//        url("#{$font_path}/inter/Inter-BlackItalic.woff") format("woff");
// }

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('#{variables.$font_path}/inter/Inter-upright.var.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('#{variables.$font_path}/inter/Inter-italic.var.woff2') format('woff2');
}

/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url('#{variables.$font_path}/inter/Inter.var.woff2') format('woff2');
}
