@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.caret {
  position: absolute;
  top: -5px;
  transform: rotate(180deg);
  left: 12px;
}

.position-left {
  left: 228px;
}

.option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
