@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/forms';

.root {
  h2 {
    margin-bottom: variables.$spacing-xl;
    font-size: variables.$font-size-a2;
  }

  form {
    @include forms.form();
    button {
      margin-top: 0;
    }
  }
}

.form {
  max-width: 320px;
}

.error {
  color: red;
}

.submit-button {
  margin-top: variables.$spacing-md;
}
