@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

@mixin active() {
  border-color: colors.$color-system-primary;
  background-color: color.adjust(colors.$color-system-primary, $lightness: 45%);
}

.root {
  max-width: 752px;
}

.download-buttons {
  margin-bottom: variables.$spacing-xl;
  display: flex;

  button {
    @include button.button-link();
  }
}

.dropzone {
  :global {
    .drop-zone {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dashed colors.$color-black-10;
      border-radius: variables.$radius-lg;
      padding: 100px;
      text-align: center;
      transition: all variables.$transition-duration variables.$transition-timing-function;

      &:hover,
      &focus {
        @include active();
      }

      button {
        @include button.button-link();
      }
    }
  }
}
