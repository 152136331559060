@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';
@use 'src/theme/styles/mixins/button';
@use 'src/theme/styles/functions';

.root {
  padding: variables.$spacing-lg;
  height: 100%;
  width: 100%;

  ul {
    @include general.reset-list;
  }
}

.heading {
  margin-top: functions.convert-to-rem(54);
  margin-bottom: functions.convert-to-rem(54);
  color: colors.$color-black-90;
}

.item {
  @include button.button-reset;
  outline: none;
  width: 100%;
  padding: variables.$spacing-md 0;
  text-decoration: none;
  display: block;
  color: colors.$color-black-secondary;
  font-size: variables.$font-size-body-1;
  font-weight: variables.$font-weight-medium;
  transition: color variables.$transition-duration variables.$transition-timing-function;

  &:hover,
  &:focus,
  &.active {
    color: colors.$color-black-primary;
  }

  &.active {
    background: url('../../../../../theme/icons/chevron-right.svg') no-repeat center right;
  }
}
