@use 'colors';
@use 'variables';

html * {
  box-sizing: border-box;
  font-feature-settings: 'ss01' 1; // https://rsms.me/inter/#features/ss01
}

html {
  line-height: 1.61803398875; // adds extra space based on font size
  margin: 0;
  font-size: 10px;
}

body {
  background-color: colors.$color-background;
  font-family: variables.$preferred-font;
  color: colors.$color-text-secondary;
  font-size: variables.$font-size-body-2;
  margin: 0;
}

a {
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
