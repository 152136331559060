@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.modal-header {
  margin-top: 0px;
}

.modal-content {
}

.modal-action {
}

.modal-info {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
}

.modal-item-wrapper {
  display: flex;
}

.modal-label {
  min-width: 150px;
  font-weight: variables.$font-weight-medium;
}
