@use 'src/theme/styles/variables';

.root {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  position: sticky;
  z-index: variables.$z-index-3;
  top: 0;
  width: 100%;
}

.body {
  padding: 0 variables.$spacing-lg;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.loading {
    position: relative;
  }
}

.footer {
  position: fixed;
  bottom: variables.$spacing-md;
  margin-left: 24px;
}
