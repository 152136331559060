@use 'src/theme/styles/variables';
// @import 'theme/styles/functions';
@use 'src/theme/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    button {
      margin-left: 4px;
      margin-top: 2px;
    }
  }
}
