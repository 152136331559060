@use 'sass:color';
@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

$width: 48px !default;
$height: 48px !default;

.root {
  @include button.button-reset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  border-radius: variables.$radius-sm;
  transition: all variables.$transition-duration variables.$transition-timing-function;
}

.state--default {
  border-color: colors.$color-black-10;
  background-color: transparent;
  // opacity: 0.8;

  &:hover,
  &:focus {
    // opacity: 1;
    border-color: color.adjust($color: colors.$color-system-primary, $alpha: -0.7);
    background-color: color.adjust($color: colors.$color-system-primary, $alpha: -0.9);
  }

  &.active {
    background-color: colors.$color-black;
    border-color: colors.$color-black;

    svg g {
      opacity: 1;

      path:last-child {
        fill: colors.$color-white;
      }
    }
  }
}

.state--danger {
  border-color: color.adjust($color: colors.$color-red, $alpha: -0.7);
  background-color: color.adjust($color: colors.$color-red, $alpha: -0.9);
  opacity: 0.8;

  &:hover,
  &:focus {
    opacity: 1;
    border-color: color.adjust($color: colors.$color-red, $alpha: -0.5);
    background-color: color.adjust($color: colors.$color-red, $alpha: -0.7);
  }
}

.no-outline {
  border-color: transparent;
}
