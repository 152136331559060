@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  display: flex;
  flex: 1;
  background: transparent;
  border: none;
  align-items: center;

  .search-wrapper {
    width: 100%;
  }

  input {
    padding: variables.$spacing-lg variables.$spacing-xxl variables.$spacing-lg variables.$spacing-xxxl;
    margin: 10px 0;
    border-radius: 90px;
    border: 1px solid colors.$color-carbon-accent;
    background: colors.$color-white url('../../../../theme/icons/search-off.svg') no-repeat 8px center;
    visibility: hidden;
    opacity: 0;
    transform: translateX(100%);
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:focus {
      background-image: url('../../../../theme/icons/search-on.svg');
      border: 1px solid colors.$color-carbon-accent;
    }
  }

  &.is-open {
    input {
      visibility: visible;
      opacity: 1;
      transform: translateX(-1%);
    }
  }
}

.children {
  position: absolute;
  width: 100%;
  text-align: left;
}

.open-button {
  @include button.button-reset;
  position: absolute;
  right: variables.$spacing-md;
  background: colors.$color-grey-tint;
  border-radius: 50%;
  width: variables.$spacing-xxl;
  height: variables.$spacing-xxl;
  transition: all variables.$transition-duration variables.$transition-timing-function;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:hover,
  &:focus {
    background: colors.$color-grey-accent;
  }
}

.search-icon {
  position: absolute;
  height: 56px;
  line-height: 56px;
  margin-left: variables.$spacing-sm;

  svg {
    height: 56px;
    padding: 11px 0;
  }
}
