@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';
@use '../../mixins';

.root {
  .header {
    @include mixins.header;
  }

  ul {
    @include general.reset-list;

    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: variables.$spacing-sm;

      span:first-of-type {
        font-size: variables.$font-size-body-1;
      }
    }
  }
}
