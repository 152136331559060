@use 'src/theme/styles/animations';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

.root {
  position: relative;
}

.hasIdentification {
  [class*='List_list-item-container'] {
    display: block;
  }

  [class*='children'] {
    display: block;
    opacity: 0.6;
    font-size: 12px;
  }
}

.showTertiaryText {
  .tertiary {
    display: block;
  }
}

.dropdown {
  max-height: 320px;
  overflow-y: scroll;
  @include general.styled-scrollbar;

  &.is-overflowing-window {
    bottom: 65px;
  }
}

.dropdown,
.no-results {
  position: absolute;
  width: 100%;
  z-index: 100;
  margin-top: variables.$spacing-sm;
  padding: variables.$spacing-sm;
  background: #ffffff;
  border: 1px solid rgba(131, 131, 131, 0.1);
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.05), 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  // mixin
  visibility: hidden;
  opacity: 0;
}

.info-text {
  p {
    margin-bottom: 0.5rem;
  }
  ul {
    margin-top: 0;
    padding-left: 2.5rem;
  }
}

// States
.open {
  position: relative;

  .dropdown,
  .no-results {
    visibility: visible;
    opacity: 1;
  }
}

.loading {
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: url('../../../../theme/icons/loader.svg') no-repeat right center;
    width: 32px;
    height: 32px;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    bottom: variables.$spacing-sm;
    right: variables.$spacing-sm;
  }
}
