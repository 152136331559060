@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/functions';
@use 'src/theme/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.datepicker-wrapper {
  display: flex;
  padding: variables.$spacing-xxl 0 variables.$spacing-md;
  max-width: 350px;
}

.status-label {
  margin: 0;
  color: #fff;
  opacity: 0.8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 90px;
  text-transform: uppercase;
  font-size: variables.$font-size;
  font-weight: variables.$font-weight-regular;
  line-height: 1.15;
}

.transaction-id-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: variables.$spacing-sm;
}

.bank-details,
.reference {
  margin-bottom: 0;
}

.reference {
  color: colors.$color-grey;
}

@media #{variables.$tablet} {
  svg {
    display: none;
  }
  strong {
    font-size: variables.$font-size-caption;
    margin-bottom: 4px;
  }
  strong,
  small {
    line-height: variables.$font-size-caption;
  }
}
