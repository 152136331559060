@use 'src/theme/styles/variables';

.root {
  display: flex;

  .input {
    flex: 1;
    margin-right: variables.$spacing-md;
  }

  .add-beneficiary-button {
    height: 48px;
    margin-top: 27px;
  }
}
