@use 'src/theme/styles/variables';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  h3 {
    font-size: variables.$font-size-a2;
    font-weight: variables.$font-weight-regular;
    margin-bottom: variables.$spacing-sm;
  }

  p {
    margin-top: 0;
  }
}
