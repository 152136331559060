@use 'src/theme/styles/mixins/dropzone';
@use 'src/theme/styles/variables';

.root {
  display: relative;

  [class*='InfoList_spacing--lg'] {
    li {
      margin-bottom: 0 !important;
    }
  }
}

.transfer-info {
  span[class*='InfoList_label'] {
    min-width: 130px;
  }
}

.dropzone {
  @include dropzone.dropzone();
  margin-top: variables.$spacing-md;
  margin-bottom: variables.$spacing-md;
}

.fileInfo {
  margin-top: variables.$spacing-md;
  margin-bottom: variables.$spacing-md;
}
