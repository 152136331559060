@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.errors {
  border: 1px solid colors.$color-error;
  background-color: colors.$color-error-tint;
  color: colors.$color-error;
  padding: variables.$spacing-sm;
  border-radius: variables.$radius-sm;
}
