@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins';

.root {
  position: relative;
  width: 100%;
}

.amount {
  margin-left: auto; // pull control to the end of container
  width: 230px;
}
