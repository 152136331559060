@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    &:first-child {
      padding-right: variables.$spacing-sm;
    }
    &:last-child {
      padding-left: variables.$spacing-sm;
    }
  }

  hr {
    flex: 1;
    border-style: dashed;
    border-color: colors.$color-carbon-tint;
  }
}
