@use '../variables';
@use '../colors';

@mixin form() {
  button {
    margin-top: variables.$spacing-xl;
  }

  :global {
    .fine-print {
      font-size: variables.$font-size-fine;
      opacity: 0.3;
      text-align: center;

      a {
        color: colors.$color-text;
        text-decoration: underline;
      }

      .error {
        color: colors.$color-red;
      }
    }
  }
}

// Creates a full height form with footer anchored at the bottom.
// Required markup:
// <form>
//  <div styleName='body'></div>
//  <div styleName='footer'></div>
// </form>
@mixin fullHeightForm() {
  display: flex;
  flex-direction: column;
  height: 100%;

  .body {
    flex-grow: 1;
  }

  .footer {
    display: flex;
    margin-top: variables.$spacing-xxl;
  }
}
