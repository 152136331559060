@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';

.root {
  margin: variables.$spacing-lg variables.$spacing-md;
  max-width: 300px;
  position: relative;

  svg[class*='LinearProgress'] {
    position: absolute;
  }

  span.completed {
    position: absolute;
    top: 2px;
    right: 0;
    svg {
      margin-right: 0;
      path {
        fill: colors.$color-teal;
      }
    }
  }

  ul[class*='List_list--interactive'] {
    li:hover {
      background: none;
      color: colors.$color-black;
      font-weight: bold;
      box-shadow: none;
    }
  }

  [class^='List_list--item__'] {
    padding: variables.$spacing-sm;
  }
}
