@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';
@use 'src/theme/styles/mixins/button';

.root {
  background: colors.$color-carbon-secondary;
  color: colors.$color-white;
  padding: 4px variables.$spacing-sm;
  border-radius: 90px;
  display: inline-flex;
  align-items: center;

  button {
    @include button.button-reset;
    @include general.ir;
    width: 20px;
    height: 20px;
    background: url('../../../../theme/icons-v2/Controls/Remove-fill.svg') no-repeat center center;
    margin-left: variables.$spacing-md;
    filter: brightness(10);
    transition: all variables.$transition-duration variables.$transition-timing-function;

    &:hover,
    &:focus {
      filter: brightness(0);
    }
  }
}
