@use 'src/theme/styles/colors';

.root {
  .open-button,
  .close-button {
    padding: 2px;
  }

  .close-button {
    background: colors.$color-carbon-primary;

    svg {
      filter: brightness(100);
    }

    &:hover,
    &:focus {
      background: colors.$color-carbon;
    }
  }
}
