@use '../colors';

@mixin button-reset() {
  border: none;
  text-align: left;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
}

@mixin button-link() {
  @include button-reset;
  cursor: pointer;
  color: colors.$color-system-primary;

  &:hover,
  &:focus {
    color: colors.$color-system;
  }
}
