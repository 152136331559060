@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/functions';
@use 'src/theme/styles/mixins/general';
@use 'src/theme/styles/mixins/button';

.root {
  padding: variables.$spacing-lg;
  height: 100%;
  overflow-y: scroll;
  @include general.styled-scrollbar;

  header {
    .controls {
      display: flex;
      align-items: center;
    }

    .paymentReference {
      font-size: functions.convert-to-rem(12);
      text-transform: uppercase;
      font-weight: variables.$font-weight-medium;
    }

    .close {
      @include button.button-reset;
      outline: none;
      margin-left: auto;
    }

    .customer {
      margin-bottom: variables.$spacing-xl;

      h2 {
        color: colors.$color-text;
        margin-bottom: 0;

        button {
          @include button.button-reset;
          background: transparent;
          border-radius: variables.$radius-sm;
          margin-bottom: 12px;
          word-break: break-word;
        }
      }

      header {
        font-size: variables.$font-size-body-1;
        font-weight: variables.$font-weight-medium;
        color: colors.$color-black-primary;
      }
    }
  }
}

.available-funds {
  display: flex;
  .amount {
    margin-left: auto;
  }
}

.open-invoices {
  header {
    display: flex;
    margin-bottom: variables.$spacing-md;

    .amount {
      margin-left: auto;
    }
  }

  ul {
    @include general.reset-list();
    li {
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-position: 0 5px;
      position: relative;
      margin: 0;

      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: colors.$color-dark-blue-primary;
        margin-right: variables.$spacing-md;
        border-radius: 90px;
      }

      span {
        margin-left: auto;
      }

      // Invoice Status Modifiers
      &.status {
        &-Short:before {
          background: colors.$color-yellow;
        }
        &-NotSet:before {
          background: colors.$color-orange;
        }
        &-Overdue:before {
          background: colors.$color-red;
        }
        &-Ready:before {
          background: colors.$color-green;
        }
      }
    }
  }
}

.closing-balance {
  display: flex;
  .amount {
    margin-left: auto;
  }
}

.recent-activity {
  @include general.reset-list();
  margin-top: variables.$spacing-md;

  li {
    display: flex;
    margin-bottom: variables.$spacing-sm;
    padding-left: variables.$spacing-xl;
    background-repeat: no-repeat;
    background-position: 0 5px;

    &.invoice {
      background-image: url('../../../../../../theme/icons/activity-invoice.svg');
    }
    &.receipt {
      background-image: url('../../../../../../theme/icons/activity-receipt.svg');
    }
    &.payment {
      background-image: url('../../../../../../theme/icons/activity-payment.svg');
    }
    &.payment-reversed {
      background-image: url('../../../../../../theme/icons/activity-payment-reversed.svg');
    }
    &.credit-note {
      background-image: url('../../../../../../theme/icons/activity-credit-note.svg');
    }

    .description {
      display: flex;
      flex-direction: column;
    }
    .amount {
      margin-left: auto;
    }
  }
}
