@use 'mixins';
@use 'mixins/fonts';

h1,
h2,
h3,
h4 {
  font-weight: 500;
}
h1 {
  @include fonts.fontBigBoy();
}
h2 {
  @include fonts.fontHeadline();
}
h3 {
  @include fonts.fontMainline();
}
h4 {
  @include fonts.fontSubheader();
}
