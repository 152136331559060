@use 'src/theme/styles/colors';
@use 'node_modules/@carbon/layout/scss/breakpoint';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/general';

@mixin container-width() {
  width: 100%;
  max-width: 1124px;
}

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 variables.$spacing-sm variables.$spacing-sm;
  background: colors.$color-carbon-primary;
  transition: all variables.$transition-duration variables.$transition-timing-function;
  transform: translateY(100%);
  z-index: variables.$z-index-5;

  // mobile
  overflow-y: scroll;
  @include general.styled-scrollbar;
}

.header {
  padding: variables.$spacing-sm;
  color: colors.$color-white-primary;
  font-size: variables.$font-size-a3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1124px;
  width: 66%;
  margin: 0 auto;

  .has-sidebar & {
    width: 100%;
  }

  a[class*='ContextMenu-button'] {
    background: colors.$color-black-accent;
    border-radius: 90%;

    svg {
      g {
        opacity: 1;
      }
      path:last-of-type {
        fill: colors.$color-white;
      }
    }
  }

  .close {
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    svg {
      background: colors.$color-black-accent;
      border-radius: 90%;

      path {
        opacity: 1;
        fill: colors.$color-white;
      }
    }
  }
}

.container {
  @include container-width;
}

.body {
  display: flex;
  justify-content: center;
  @include container-width;

  // mobile
  flex-direction: column;

  // medium screens up
  @include breakpoint.carbon--breakpoint-up('md') {
    flex-direction: row;
  }
}

.content {
  background: colors.$color-white;
  overflow-x: hidden;

  // mobile
  padding: variables.$spacing-sm;
  width: 100%;
  margin-bottom: variables.$spacing-sm;
  min-height: 90vh;

  // medium screens up
  @include breakpoint.carbon--breakpoint-up('md') {
    width: 66%;
    margin-bottom: 0;
    padding: variables.$spacing-lg;
  }
}

.sidebar {
  padding: variables.$spacing-sm;
  background: colors.$color-bg-mercury;

  // mobile
  width: 100%;

  @include breakpoint.carbon--breakpoint-up('md') {
    width: 44%;
    max-width: 375px;
    padding: variables.$spacing-md;

    > div[class*='SidebarNav_root'] {
      position: sticky;
      top: variables.$spacing-lg;
    }
  }
}

.menu {
  svg {
    > g {
      opacity: 1;
    }
    path:last-of-type {
      fill: colors.$color-white;
    }
  }
}

.disable-scroll {
  overflow-y: initial;
}
